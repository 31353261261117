var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c("div", { staticClass: "global-container ml-3" }, [
      _vm.authenticated
        ? _c(
            "nav",
            { staticClass: "navbar navbar-expand-sm navbar-dark bg-primary" },
            [
              _vm._m(0),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass: "collapse navbar-collapse",
                  attrs: { id: "navbarSupportedContent" },
                },
                [
                  _c("ul", { staticClass: "navbar-nav me-auto mb-2 mb-lg-0" }, [
                    _c(
                      "li",
                      { staticClass: "nav-item" },
                      [
                        _c(
                          "router-link",
                          {
                            staticClass: "nav-link",
                            class:
                              _vm.currentRoutePath === _vm.adAccountCreatorRoute
                                ? "active"
                                : "",
                            attrs: {
                              disabled:
                                _vm.currentRoutePath ===
                                _vm.adAccountCreatorRoute,
                              to: _vm.adAccountCreatorRoute,
                            },
                          },
                          [_vm._v("Ad Account Creator")]
                        ),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "li",
                      { staticClass: "nav-item" },
                      [
                        _c(
                          "router-link",
                          {
                            staticClass: "nav-link",
                            class:
                              _vm.currentRoutePath ===
                              _vm.creatorInfoLoaderRoute
                                ? "active"
                                : "",
                            attrs: {
                              disabled:
                                _vm.currentRoutePath ===
                                _vm.creatorInfoLoaderRoute,
                              to: _vm.creatorInfoLoaderRoute,
                            },
                          },
                          [_vm._v("Ad Account Tools")]
                        ),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "li",
                      { staticClass: "nav-item" },
                      [
                        _c(
                          "router-link",
                          {
                            staticClass: "nav-link",
                            class:
                              _vm.currentRoutePath === _vm.facebookReportsRoute
                                ? "active"
                                : "",
                            attrs: {
                              disabled:
                                _vm.currentRoutePath ===
                                _vm.facebookReportsRoute,
                              to: _vm.facebookReportsRoute,
                            },
                          },
                          [_vm._v("Reports")]
                        ),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "li",
                      { staticClass: "nav-item" },
                      [
                        _c(
                          "router-link",
                          {
                            staticClass: "nav-link",
                            class:
                              _vm.currentRoutePath === _vm.pageManagerRoute
                                ? "active"
                                : "",
                            attrs: {
                              disabled:
                                _vm.currentRoutePath === _vm.pageManagerRoute,
                              to: _vm.pageManagerRoute,
                            },
                          },
                          [_vm._v("Page Manager")]
                        ),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _vm.showTemplateManager
                      ? _c(
                          "li",
                          { staticClass: "nav-item" },
                          [
                            _c(
                              "router-link",
                              {
                                staticClass: "nav-link",
                                class:
                                  _vm.currentRoutePath ===
                                  _vm.templateManagerRoute
                                    ? "active"
                                    : "",
                                attrs: {
                                  disabled:
                                    _vm.currentRoutePath ===
                                    _vm.templateManagerRoute,
                                  to: _vm.templateManagerRoute,
                                },
                              },
                              [_vm._v("Template Manager")]
                            ),
                          ],
                          1
                        )
                      : _vm._e(),
                  ]),
                ]
              ),
              _vm._v(" "),
              _c("div", { staticClass: "d-flex text-light" }, [
                _c("div", { staticClass: "nav-link pl-3" }, [
                  _vm._v(" " + _vm._s(_vm.userName) + " "),
                ]),
                _vm._v(" "),
                _c(
                  "a",
                  {
                    staticClass: "nav-link",
                    staticStyle: { cursor: "pointer" },
                    attrs: { title: "Log Out" },
                    on: {
                      click: function ($event) {
                        return _vm.logout()
                      },
                    },
                  },
                  [
                    _c("font-awesome-icon", {
                      staticStyle: { margin: "6px", "margin-right": "20px" },
                      attrs: { icon: "sign-out-alt" },
                    }),
                  ],
                  1
                ),
              ]),
            ]
          )
        : _vm._e(),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "router-view-container" },
        [_c("router-view")],
        1
      ),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "contain-fluid" }, [
      _c(
        "a",
        {
          staticClass: "navbar-brand ml-3",
          staticStyle: { "margin-left": "20px" },
          attrs: { href: "#" },
        },
        [_vm._v("\n                    Facebook Automation\n                ")]
      ),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }