import { Company } from "../../enums/Company";

export class UpdatePageRequest {
    public PageID: string = ``;
    public DDID: string = '';
    public DomainName: string = '';
    public About: string = '';
    public Phone: string = '';
    public Website: string = '';
    public Company: Company = Company.CINC;
}