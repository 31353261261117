import Vue from 'vue';
import Vuex from 'vuex';
import ad from './Modules/ad.store';
import adAccounts from './Modules/adaccount.store';
import leadForm from './Modules/leadform.store';
import location from './Modules/location.store';
import productset from './Modules/productset.store';
import creator from './Modules/creator.store';
import admin from './Modules/admin.store';
import report from './Modules/report.store';
import page from './Modules/page.store';
import managedPage from './Modules/managedpage.store';
import cincPlatform from './Modules/cincplatform.store';
import { FacebookMarketingFactory } from '../services/FacebookMarketingFactory';
import * as jose from 'jose'

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    facebookMarketingFactory : null,
    userName : "",
    accessToken : undefined
  },
  getters:{
    accessToken(state: any) {
      //checking to ensure cookie hasn't been tampered with
      let token = document.cookie.match('(^|;)?' + "cinc_access_token" + '=([^;]*)(;|$)');
      if(token !== null && token !== undefined && token.length > 0) {
        if(state.accessToken !== token[2]) {
          state.accessToken = token[2];
        }
      }
      else{
        state.accessToken = null;
      }
      return state.accessToken;
    },
    facebookMarketingFactory(state, getters) {
      if(state.facebookMarketingFactory === null || state.facebookMarketingFactory === undefined) {
        state.facebookMarketingFactory = new FacebookMarketingFactory(`Bearer ${getters.accessToken}`);
      }
      return state.facebookMarketingFactory;
    },
    userName(state, getters) {
      if(state.userName === "" || state.userName === undefined) {
        var claims = jose.decodeJwt(getters.accessToken);
        state.userName = claims.name;
      }
      return state.userName;
    }
  },
  mutations: {
    createAccessToken(state: any, token: string){
      var claims = jose.decodeJwt(token);
      var expireDate = new Date();
      if(claims.exp)
        expireDate = new Date(claims.exp * 1000);
      
      document.cookie = `cinc_access_token=${token}; expires=${expireDate.toUTCString()}; path=/;`;
      state.accessToken = token;
    },
    createUserName(state: any, name: string){
      state.userName = name;
    },
    createFacebookMarketingFactory(state, accessToken) {
      state.facebookMarketingFactory = new FacebookMarketingFactory(`Bearer ${accessToken}`);
    },
    deleteFacebookMarketingFactory (state : any) {
      state.facebookMarketingFactory = null;
    },
    deleteAccessToken (state : any) {
      state.accessToken = null;
      document.cookie = `cinc_access_token=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;
    }
  },
  actions: {
    async setAccessToken(context: any, token:string){
      context.commit('createAccessToken', token);
      var claims = jose.decodeJwt(token);
      context.commit('createUserName', claims.name);
      context.commit('createFacebookMarketingFactory', token);
    },
    async isAuthenticated({commit, state, rootState, getters} : {commit:any, state:any, rootState: any, getters: any}) {
      let token = document.cookie.match('(^|;)?' + "cinc_access_token" + '=([^;]*)(;|$)');
      return !(getters.accessToken === undefined || getters.accessToken === null || token === null);
    },
    async logout(context : any) {
      context.commit('deleteFacebookMarketingFactory');
      context.commit('deleteAccessToken');
    }
  },
  modules: {
    ad,
    leadForm,
    location,
    adAccounts,
    creator,
    admin,
    page,
    productset,
    report,
    managedPage,
    cincPlatform
  },
})