<template>
    <div id="page-associator">
        <div class="display-6 m-2">Page Manager</div>
        <form id="page-update-form" class="needs-validation" novalidate>
            <div class="row m-3">
                <div class="col-md-9">
                    <label for="company" class="m-1 form-label">Company</label>
                    <select v-model="company" class="form-select" id="company" @change="clearPage()" required>
                        <option selected disabled value=0>Please Select a Company</option>
                        <option value=1>CINC</option>
                        <option value=2>Real Geeks</option>
                    </select>
                </div>
            </div>
            <div v-if="isCompanySelected">
                <div v-if="!isPageSelected && isCincSelected">
                    <div v-if="isPagesLoading">
                        <div class="row m-3 mt-5">
                            <div class="col-md-9 text-center">
                                <span class="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span>
                                <span>Loading...</span>
                            </div>
                        </div>
                        
                    </div>
                    <div v-else>
                        <div v-if="isDisplayImportPage">
                            <div class="row m-3">
                                <div class="col-md-9">
                                    <label class="m-1" for="importPageId">Page ID</label>
                                    <input type="text"  class="form-control" v-model="importPageRequest.PageID" id="importPageId" placeholder="Page ID" required />
                                </div>
                            </div>
                            <div class="row m-3">
                                <div class="col-md-9">
                                    <button :disabled=isImporting class="btn btn-primary" @click="importPage">
                                        <span v-if="isImporting" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                        Import
                                    </button>
                                    <button :disabled=isImporting class="btn btn-secondary" @click="cancelImport">Cancel</button>
                                </div>
                            </div>
                            <div v-if="displayImportError">
                                <div class="row m-4">
                                    <div class="col-md-9 alert alert-danger">
                                        <p class="display-1 text-danger text-center"><font-awesome-icon :icon="['far', 'xmark']"/></p>
                                        <h3 class="text-center text-danger m-2">Failure</h3>
                                        <p class="text-center m-2">{{ importResponseMessage }}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div v-else>
                            <div class="row m-3">
                                <div class="col-md-6">
                                    <div class="form-group">
                                        <label class="m-1" for="pageSearch">Search Existing Managed Pages:</label>
                                        <input id="pageSearch" class="form-control" type="text" v-model="pageSearchInput" placeholder="Search by page name or ID" />
                                    </div>
                                </div>
                                <div class="col-md-1 mt-4">
                                    <p class="mt-2"> - or - </p>
                                </div>
                                <div class="col-md-2">
                                    <button class="btn btn-secondary mt-4" @click="displayImportPage">Import New</button>
                                </div>
                            </div>  
                            <div class="row m-2 mt-0" v-if="isSearchedForPage">
                                <div class="col-md-9 overflow-auto" style="max-height: 520px; cursor: pointer;">
                                    <ul class="list-group" v-for="(page, index) in filteredPages" :key="page.PageID">
                                        <li class="list-group-item" :class="getPageClass(index)" @click="pageClick(page)" style="cursor: pointer">
                                            <div class="d-flex w-100 justify-content-between">
                                                <h6 class="mb-1">{{ page.PageName }} </h6>
                                            </div>
                                            <small class="mb-1">{{ page.PageID }} - {{ page.DomainName }} - {{ page.DomainStatus }}</small>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div v-else>
                    <div v-if="isCincSelected" class="row m-3">
                        <div class="col-md-9">
                            <div class="list-group">
                                <li class="list-group-item">
                                    <div class="d-flex w-100 justify-content-between">
                                        <h6 class="mb-1">{{ selectedPageName }}</h6>
                                        <span @click="clearPage()" class="badge bg-secondary rounded-pill" style="cursor: pointer;">
                                            <font-awesome-icon icon="xmark" style="margin: 2px; margin-right: 2px;"></font-awesome-icon>
                                        </span>
                                    </div>
                                    <small>{{ updateRequest.PageID }}</small>
                                </li>
                            </div>
                        </div>
                    </div>
                    <div v-else>
                        <div class="row m-3">
                            <div class="col-md-9">
                                <label class="m-1" for="pageId">Page ID</label>
                                <input type="text" v-model="updateRequest.PageID" class="form-control" id="pageId" placeholder="Page ID" required />
                            </div>
                        </div>
                    </div>
                    <div v-if="isCincSelected">
                        <div v-if="!displayDomainSearch" class="row m-3">
                            <div class="col-md-9">
                                <label class="m-1" for="domain">Selected Domain</label>
                                <div class="input-group">
                                    <input type="text" disabled v-model="updateRequest.DomainName" id="domain" class="form-control" placeholder="Domain">
                                    <div class="input-group-append">
                                        <button class="btn btn-outline-secondary" type="button" @click="changeDomainClick">Change</button>
                                    </div>
                                </div>
                                <small class="form-text text-muted small">DDID: {{ updateRequest.DDID }}</small>
                            </div>
                        </div>
                        <div class="row m-3" v-if="displayDomainSearch">
                            <div class="row">
                                <div class="col-md-8">
                                    <label class="" for="domainSearch">Search for CINC domains</label>
                                    <div class="input-group">
                                        <input type="text" v-model="cincDomainSearchInput" class="form-control" id="domainSearch" placeholder="Search for a CINC domain" />
                                        <div class="input-group-append">
                                            <button class="btn btn-primary" @click="searchCincPlatforms">Search</button>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-1 mt-4">
                                    <button class="btn btn-secondary" @click="cancelSearchCincPlatforms">Cancel</button>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-9 m-3 overflow-auto" style="max-height: 520px; cursor: pointer;">
                                    <ul class="list-group" v-for="(cincDomain, index) in cincDomainSearchResults" :key="cincDomain.Id">
                                        <li class="list-group-item" :class="getPageClass(index)" @click="cincDomainClick(cincDomain)" style="cursor: pointer">
                                            <div class="d-flex w-100 justify-content-between">
                                                <h6 class="mb-1">{{ cincDomain.DomainName }}</h6>
                                            </div>
                                            <small class="mb-1 small">{{ cincDomain.Status }}</small>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div v-if="displayPageProperties">
                        <div class="row m-3">
                            <div class="col-md-9">
                                <label class="m-1" for="about">About/Bio</label>
                                <textarea style="height: 150px;" v-model="updateRequest.About" class="form-control" id="about" placeholder="About/Bio" required />
                            </div>
                        </div>
                        <div class="row m-3">
                            <div class="col-md-9">
                                <label class="m-1" for="Phone">Phone</label>
                                <input type="text" v-model="updateRequest.Phone" class="form-control" id="phone" placeholder="Phone Number" required />
                                <small class="form-text text-muted small">Should start with the country code and only be numerical characters</small>
                            </div>
                        </div>
                        <div class="row m-3">
                            <div class="col-md-9">
                                <label class="m-1" for="website">Website URL</label>
                                <input type="text" v-model="updateRequest.Website" class="form-control" id="website" placeholder="https://www.site-domain.com" required />
                                <small class="form-text text-muted small">Should start with https:// or http://</small>
                            </div>
                        </div>
                        <div class="row m-3">
                            <div class="col-md-9">
                                <button :disabled=disableUpdate  class="btn btn-primary mt-3" @click="updateManagedPage">
                                    <span v-if="isUpdating" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                    Update
                                </button>
                                <button :disabled=disableUpdate class="btn btn-secondary mt-3" @click="clearPage()">Cancel</button>
                            </div>
                        </div>
                    </div>
                    
                </div>
                <div v-if="displaySuccess">
                    <div class="row m-4">
                        <div class="col-md-9 alert alert-success">
                            <p class="display-1 text-success text-center"><font-awesome-icon :icon="['far', 'check-circle']"/></p>
                            <h3 class="text-center text-success m-2">Success!</h3>
                            <p class="text-center m-2">The page has been updated in Facebook and in our CINC system</p>
                        </div>
                    </div>
                </div>
                <div v-if=displayPartialSuccess>
                    <div class="row m-4">
                        <div class="col-md-9 alert alert-warning">
                            <p class="display-1 text-warning text-center"><font-awesome-icon :icon="['far', 'check-circle']"/></p>
                            <h3 class="text-center text-warning m-2">Partial Success</h3>
                            <p class="text-center m-2">{{ updateResponseMessage }}</p>
                        </div>
                    </div>
                </div>
                <div v-if="displayError">
                    <div class="row m-4">
                        <div class="col-md-9 alert alert-danger">
                            <p class="display-1 text-danger text-center"><font-awesome-icon :icon="['far', 'xmark']"/></p>
                            <h3 class="text-center text-danger m-2">Failure</h3>
                            <p class="text-center m-2">{{ updateResponseMessage }}</p>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    </div>
</template>

<style></style>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator';
import { ManagedPage } from '../../../models/ManagedPage';
import { CincPlatform } from '../../../models/CincPlatform';
import { UpdatePageRequest } from '../../../models/request/UpdatePageRequest';
import { ImportPageRequest } from '../../../models/request/ImportPageRequest';
import { Company } from '../../../enums/Company';

@Component
export default class PageManager extends Vue {
    
    form: any;

    displaySuccess: boolean = false;
    displayPartialSuccess: boolean = false;
    displayError: boolean = false;
    displayImportError: boolean = false;
    displayDomainSearch: boolean = false;
    isDisplayImportPage: boolean = false;
    isPagesLoading : boolean = false;
    isUpdating: boolean = false;
    isImporting: boolean = false;
    disableUpdate: boolean = false;

    company: string = "0";
    importResponseMessage: string = "";
    updateResponseMessage: string = "";
    selectedPageName: string = "";
    pageSearchInput: string = "";
    cincDomainSearchInput : string = "";

    cincDomainSearchResults: CincPlatform[] = new Array<CincPlatform>();
    managedPages: ManagedPage[] = new Array<ManagedPage>();
    updateRequest: UpdatePageRequest = new UpdatePageRequest();
    importPageRequest: ImportPageRequest = new ImportPageRequest();

    get isCompanySelected() : boolean {
        return this.company !== "0";
    }

    get isCincSelected() : boolean {
        return this.company === "1";
    }

    get filteredPages() : ManagedPage[] {
        if(this.pageSearchInput === "") {
            return this.managedPages;
        }
        return this.managedPages.filter((page : ManagedPage) => page.PageName.toLowerCase().includes(this.pageSearchInput.toLowerCase()) || page.PageID.includes(this.pageSearchInput));
    }

    get isSearchedForPage() : boolean {
        return this.pageSearchInput.length > 0;
    }

    get isPageSelected() : boolean {
        return this.updateRequest.PageID !== "";
    }

    get displayPageProperties() : boolean {
        return !this.displayDomainSearch || !this.isCincSelected;
    }

    getPageClass(index: number) {
        return index % 2 == 0 ? '':'list-group-item-light';
    }

    async loadManagedPages() {
        this.isPagesLoading = true;
        this.managedPages = await this.$store.dispatch('managedPage/getAllManagedPages');
        this.isPagesLoading = false;
    }

    async pageClick(page: ManagedPage) {
        this.selectedPageName = page.PageName;
        this.updateRequest.PageID = page.PageID;
        this.updateRequest.DDID = page.DDID === undefined || page.DDID === null ? "" : page.DDID;
        this.updateRequest.DomainName = page.DomainName;
        this.updateRequest.About = page.About;
        this.updateRequest.Phone = page.Phone;
        this.updateRequest.Website = page.Website;
                
        if(this.isCincSelected && (page.DomainName === undefined || page.DomainName === null || page.DomainName === "") && !(page.DDID === "" || page.DDID === null || page.DDID === undefined)) {
            let cincPlatform : CincPlatform = await this.$store.dispatch('cincPlatform/getCincPlatform', page.DDID);
            if(cincPlatform !== null && cincPlatform !== undefined) {
                this.updateRequest.DomainName = cincPlatform.DomainName;
                if(page.Website === undefined || page.Website === null || page.Website === "") {
                    this.updateRequest.Website = `https://www.${cincPlatform.DomainName}`;
                }
            }
        }
    }

    async cincDomainClick(cincDomain: CincPlatform) {
        this.updateRequest.DomainName = cincDomain.DomainName;
        this.updateRequest.DDID = cincDomain.Id;
        this.updateRequest.Website = `https://www.${cincDomain.DomainName}`;

        let countryCode = cincDomain.Country === "US" || "CA" ? "1" : cincDomain.Country === "MX" ? "52" : "";
        if(!(cincDomain.ContactPhone === null || cincDomain.ContactPhone === undefined))
            this.updateRequest.Phone =  `${countryCode}${cincDomain.ContactPhone.replace(/\D/g,'')}`;

        this.updateRequest.About = `${cincDomain.CompanyName}\r\n${cincDomain.ContactFirstName} ${cincDomain.ContactLastName}\r\n${cincDomain.ContactAddress}\r\n${cincDomain.ContactCity}, ${cincDomain.ContactState} ${cincDomain.ContactZip}\r\n${cincDomain.ContactPhone}`;
        this.displayDomainSearch = false;
        this.cincDomainSearchInput = ``;
        this.cincDomainSearchResults = new Array();
    }

    async changeDomainClick() {
        this.displayDomainSearch = true;
    }

    clearPage() {
        if(this.company === Company.CINC){
            this.loadManagedPages();
        }
        this.updateRequest = new UpdatePageRequest();
        this.displaySuccess = false;
        this.displayPartialSuccess = false;
        this.displayError = false;
        this.pageSearchInput = "";
        this.displayDomainSearch = false;
        if(this.form)
            this.form.classList.remove('was-validated');
    }

    displayImportPage(e: Event) {
        e.preventDefault();
        e.stopPropagation();
        this.isDisplayImportPage = true;
    }

    async importPage(e :Event) {
        e.preventDefault();
        e.stopPropagation();
        this.displayImportError = false;
        this.isImporting = true;
        await this.$store.dispatch('managedPage/importManagedPage', this.importPageRequest)
            .then(async (response: any) => {
                await this.pageClick(<ManagedPage>response);
                this.isDisplayImportPage = false;        
            })
            .catch((error: any) => {
                this.displayImportError = true;
                this.importResponseMessage = error;
            })
            .finally(() => {
                this.isImporting = false;
                this.cincDomainSearchInput = "";
            });
    }

    async searchCincPlatforms(e: Event) {
        e.preventDefault();
        e.stopPropagation();
        this.cincDomainSearchResults = await this.$store.dispatch('cincPlatform/getCincPlatforms', this.cincDomainSearchInput);
    }

    async cancelSearchCincPlatforms(e: Event) {
        e.preventDefault();
        e.stopPropagation();
        this.cincDomainSearchInput = '';
        this.cincDomainSearchResults = new Array();
        this.displayDomainSearch = false;
    }

    async cancelImport(e: Event) {
        e.preventDefault();
        e.stopPropagation();
        this.isDisplayImportPage = false;
        this.displayImportError = false;
        this.importResponseMessage = '';
        this.importPageRequest.PageID = '';
    }

    async updateManagedPage(e: Event) {
        this.displayError = false;
        this.displaySuccess = false;
        this.displayPartialSuccess = false;
        this.form = document.getElementsByClassName('needs-validation')[0];
        this.form.classList.add('was-validated');
        if(this.form){
            e.preventDefault();
            e.stopPropagation();
            if (this.form.checkValidity() === true) { 
                this.disableUpdate = true;
                if(this.isCincSelected) { 
                    this.updateRequest.Company = Company.CINC;
                }
                else {
                    this.updateRequest.Company = Company.RealGeeks;
                    this.updateRequest.DDID = "";
                    this.updateRequest.DomainName = "";
                }
                this.isUpdating = true;
                await this.$store.dispatch('managedPage/updateManagedPage', this.updateRequest)
                .then((response) => {
                    
                    if(response.status === 200) {
                        this.displaySuccess = true;
                        this.displayPartialSuccess = false;
                        this.$data.updateResponseMessage = "";
                    }
                    if(response.status === 206) {
                        this.displayPartialSuccess = true;
                        this.displaySuccess = false;
                        this.updateResponseMessage = response.data;
                    }
                })
                .catch((error) => {
                    this.displayError = true;
                    this.updateResponseMessage = `An error occurred while updating the page. ${error}`;
                }).finally(() => {
                    this.disableUpdate = false;
                    this.isUpdating = false;
                }); 
            }
        }
    }
}

</script>