var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { attrs: { id: "location-selector" } },
    [
      _c("label", { attrs: { for: "locations" } }, [
        _vm._v("Targeting Location Search"),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "row g-3" }, [
        _vm._m(0),
        _vm._v(" "),
        _c("div", { staticClass: "col-md-6" }, [
          _c("div", { attrs: { id: "search-area" } }, [
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model.trim",
                  value: _vm.searchInput,
                  expression: "searchInput",
                  modifiers: { trim: true },
                },
              ],
              staticClass: "form-control",
              attrs: {
                type: "search",
                id: "search-input",
                placeholder: "Search Locations",
              },
              domProps: { value: _vm.searchInput },
              on: {
                focus: _vm.checkShowResults,
                input: function ($event) {
                  if ($event.target.composing) return
                  _vm.searchInput = $event.target.value.trim()
                },
                blur: function ($event) {
                  return _vm.$forceUpdate()
                },
              },
            }),
            _vm._v(" "),
            _vm.showResults
              ? _c(
                  "div",
                  { staticClass: "container search-results-container" },
                  _vm._l(_vm.locationResults, function (location) {
                    return _c(
                      "div",
                      {
                        key: location.key,
                        staticClass: "row row-hover",
                        on: {
                          click: function ($event) {
                            return _vm.clickSearchResult(location)
                          },
                        },
                      },
                      [
                        _c("div", { staticClass: "col border p-2" }, [
                          _vm._v(
                            "\n                                " +
                              _vm._s(location.name) +
                              ", " +
                              _vm._s(location.region) +
                              "  " +
                              _vm._s(location.country_code) +
                              " "
                          ),
                          _c(
                            "span",
                            {
                              staticClass: "badge bg-light text-dark",
                              staticStyle: { float: "right" },
                            },
                            [_vm._v(_vm._s(location.type))]
                          ),
                        ]),
                      ]
                    )
                  }),
                  0
                )
              : _vm._e(),
          ]),
        ]),
      ]),
      _vm._v(" "),
      _c(
        "label",
        { staticClass: "from-control", attrs: { for: "selected-locations" } },
        [_vm._v("Selected Locations:")]
      ),
      _vm._v(" "),
      _vm._l(_vm.locationSelectorResults.regions, function (region) {
        return _c(
          "div",
          {
            key: region.key,
            staticClass: "border p-2",
            staticStyle: { display: "flex" },
          },
          [
            _c("div", { staticClass: "col-md-6" }, [
              _vm._v(
                "\n                " +
                  _vm._s(region.name) +
                  " - " +
                  _vm._s(region.country) +
                  " "
              ),
              _c("span", { staticClass: "badge badge-light" }, [
                _vm._v("Region"),
              ]),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "col-md-5" }),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "col-md-1" },
              [
                _c("font-awesome-icon", {
                  staticStyle: { float: "right", cursor: "pointer" },
                  attrs: { icon: ["far", "circle-xmark"] },
                  on: {
                    click: function ($event) {
                      return _vm.clickRemoveRegion(region)
                    },
                  },
                }),
              ],
              1
            ),
          ]
        )
      }),
      _vm._v(" "),
      _vm._l(_vm.locationSelectorResults.counties, function (county) {
        return _c(
          "div",
          {
            key: county.key,
            staticClass: "border p-2",
            staticStyle: { display: "flex" },
          },
          [
            _c("div", { staticClass: "col-md-6" }, [
              _vm._v(
                "\n                " +
                  _vm._s(county.name) +
                  " - " +
                  _vm._s(county.region) +
                  " - " +
                  _vm._s(county.country_code) +
                  " "
              ),
              _c("span", { staticClass: "badge badge-light" }, [
                _vm._v("County"),
              ]),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "col-md-5" }),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass:
                  "col-md-1 d-flex align-items-center justify-content-end",
              },
              [
                _c("font-awesome-icon", {
                  staticStyle: { float: "right", cursor: "pointer" },
                  attrs: { icon: ["far", "circle-xmark"] },
                  on: {
                    click: function ($event) {
                      return _vm.clickRemoveCounty(county)
                    },
                  },
                }),
              ],
              1
            ),
          ]
        )
      }),
      _vm._v(" "),
      _vm._l(_vm.locationSelectorResults.cities, function (city) {
        return _c(
          "div",
          {
            key: city.key,
            staticClass: "border p-2",
            staticStyle: { display: "flex" },
          },
          [
            _c("div", { staticClass: "col-md-6" }, [
              _vm._v(
                "\n                " +
                  _vm._s(city.name) +
                  ", " +
                  _vm._s(city.region) +
                  " - " +
                  _vm._s(city.country) +
                  "  "
              ),
              _c("span", { staticClass: "badge badge-light" }, [
                _vm._v("City"),
              ]),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "col-md-5" }, [
              _c("span", [
                _vm._v("Radius : "),
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: city.radius,
                      expression: "city.radius",
                    },
                  ],
                  staticClass: "form-item",
                  attrs: { type: "number", min: "15", max: "50", value: "20" },
                  domProps: { value: city.radius },
                  on: {
                    input: function ($event) {
                      if ($event.target.composing) return
                      _vm.$set(city, "radius", $event.target.value)
                    },
                  },
                }),
                _vm._v(" miles"),
              ]),
            ]),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass:
                  "col-md-1 d-flex align-items-center justify-content-end",
              },
              [
                _c("font-awesome-icon", {
                  staticStyle: { float: "right", cursor: "pointer" },
                  attrs: { icon: ["far", "circle-xmark"] },
                  on: {
                    click: function ($event) {
                      return _vm.clickRemoveCity(city)
                    },
                  },
                }),
              ],
              1
            ),
          ]
        )
      }),
      _vm._v(" "),
      _vm.locationValidation
        ? _c("span", { staticClass: "validation" }, [
            _vm._v(_vm._s(_vm.locationValidation)),
          ])
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("div", { staticClass: "col-md-6" }, [
      _c("div", { staticClass: "mb-3" }, [
        _c("input", {
          staticClass: "form-control",
          attrs: {
            type: "text",
            disabled: "",
            readonly: "",
            id: "locationTypes",
            value: "People living in or recently in this location",
          },
        }),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }