import { AdAccountCreationRequest } from "../../models/request/AdAccountCreationRequest";
import { AdsetCreationRequest } from "../../models/request/AdsetCreationRequest";
import { AdCreationRequest } from "../../models/request/AdCreationRequest";
import { CampaignCreationRequest } from "../../models/request/CampaignCreationRequest";
import { CreatorInfo, CreatorInfoCampaign, CreatorInfoAdset } from "../../models/CreatorInfo";
import { CreatorInfoCreationRequest, FormCreationRequest } from "../../models/FormCreationRequest";
import { ICreatorState } from "../Interfaces/ICreatorState";

const creatorStore = ({
    namespaced: true,
    state: {
        creatorInfo: new CreatorInfo,
        activeItem: null,
        selectedCampaignId: "",
        selectedAdsetId:  "",
        selectedFormId: "",
        existingCreatorInfos: new Array()
    } as ICreatorState,
    getters: {
        selectedCampaign: (state: ICreatorState) => {
            let campaignId = state.selectedCampaignId;
            return state.creatorInfo.Campaigns.find((campaign: CreatorInfoCampaign) => campaign.CampaignId === campaignId);
        },
        selectedAdSet: (state: ICreatorState) => {
            let adSetId = state.selectedAdsetId;
            let campaignId = state.selectedCampaignId;
            let result = state.creatorInfo.Campaigns.find((campaign : CreatorInfoCampaign) => campaign.CampaignId === campaignId)?.Adsets.find((adset: CreatorInfoAdset) => adset.AdsetId === adSetId);
            return result;
        }
    },
    mutations:{
        setCreatorInfo(state: ICreatorState, creatorInfo : CreatorInfo) {
            state.creatorInfo = creatorInfo;
        },
        setSelectedCampaignId(state: ICreatorState, campaignId: string) {
            state.selectedCampaignId = campaignId;
        },
        setSelectedAdsetId(state: ICreatorState, adsetId: string) {
            state.selectedAdsetId = adsetId;
        },
        setSelectedFormId(state: ICreatorState, formId: string) {
            state.selectedFormId = formId;
        },
        setActiveItem(state: ICreatorState, item: any) {
            state.activeItem = item;
        },
        clearCreatorInfo(state: ICreatorState) {
            state.creatorInfo = new CreatorInfo();
        },
        setExistingCreatorInfos(state: ICreatorState, existingCreatorInfos: CreatorInfo[]) {
            state.existingCreatorInfos = existingCreatorInfos;
        }
    },
    actions:{
        clearCreatorInfo({commit} : {commit: any}) {
            commit('clearCreatorInfo');
        },
        setActiveItem({commit} : {commit: any}, item: any) {
            commit('setActiveItem', item);
        },
        setSelectedCampaignId({commit} : {commit: any}, campaignId: string) {
            commit('setSelectedCampaignId', campaignId);
        },
        setSelectedAdsetId({commit} : {commit: any}, adsetId: string) {
            commit('setSelectedAdsetId', adsetId);
        },
        setSelectedFormId({commit} : {commit: any}, formId: string) {
            commit('setSelectedFormId', formId);
        },
        setCreatorInfo({commit} : {commit: any}, creatorInfo : CreatorInfo) {
            commit('setCreatorInfo', creatorInfo);
        },
        async createCincConfiguredAdAccount({commit, rootState, rootGetters} : {commit:any, state:any, rootState: any, rootGetters :any}, adAccountCreationRequest : AdAccountCreationRequest) {
            return new Promise((resolve, reject) => {
                rootGetters.facebookMarketingFactory.CreateCincConfiguredAdAccount(adAccountCreationRequest)
                .then((response : any) => {
                    resolve(response);
                }, (error : any) => {
                    reject(error);
                })
            })
        },
        async createCincConfiguredCampaign({commit, rootState, rootGetters} : {commit:any, state:any, rootState: any, rootGetters :any}, campaignCreationRequest : CampaignCreationRequest){
            return new Promise((resolve, reject) => {
                rootGetters.facebookMarketingFactory.CreateCincConfiguredCampaign(campaignCreationRequest)
                .then((response : any) => {
                    resolve(response);
                }, (error : any) => {
                    reject(error);
                })
            })
        },
        async createCincConfiguredAdset({commit, rootState, rootGetters} : {commit:any, state:any, rootState: any, rootGetters :any}, adsetCreationRequest : AdsetCreationRequest){
            return new Promise((resolve, reject) => {
                rootGetters.facebookMarketingFactory.CreateCincConfiguredAdset(adsetCreationRequest)
                .then((response : any) => {
                    resolve(response);
                }, (error : any) => {
                    reject(error);
                })
            })
        },
        async createCincConfiguredAd({commit, rootState, rootGetters} : {commit:any, state:any, rootState: any, rootGetters :any}, adCreationRequest : AdCreationRequest){
            return new Promise((resolve, reject) => {
                rootGetters.facebookMarketingFactory.CreateCincConfiguredAd(adCreationRequest)
                .then((response : any) => {
                    resolve(response);
                }, (error : any) => {
                    reject(error);
                })
                  })
        },
        async createCincConfiguredForm({commit, rootState, rootGetters} : {commit:any, state:any, rootState: any, rootGetters :any}, formCreationRequest: FormCreationRequest) {
            return new Promise((resolve, reject) => {
                rootGetters.facebookMarketingFactory.CreateCincConfiguredForm(formCreationRequest)
                .then((response : any) => {
                    resolve(response);
                }, (error : any) => {
                    reject(error);
                })
            })
        },
        async getExistingCreatorInfos({commit, state, rootState, rootGetters} : {commit:any, state:any, rootState: any, rootGetters :any}, forceUpdate: boolean = false) {
            if(forceUpdate || state.existingCreatorInfos === undefined || state.existingCreatorInfos === null || state.existingCreatorInfos.length === 0) {
                await rootGetters.facebookMarketingFactory.GetExistingCreatorInfos()
                .then((response : any) => {
                    commit('setExistingCreatorInfos', response);
                });
            }
            return state.existingCreatorInfos;
        },
        async loadCreatorInfo({commit, state, rootState, rootGetters} : {commit:any, state:any, rootState: any, rootGetters :any}, adAccountId: string) {
            return new Promise((resolve, reject) => {
                rootGetters.facebookMarketingFactory.GetCreatorInfo(adAccountId)
                    .then((response : any) => {
                        commit('setCreatorInfo', response);
                        resolve(response);
                    }, (error : any) => {
                        reject(error);
                    })
            })
        },
        async createAdAccountCreatorInfo({commit, state, rootState, rootGetters} : {commit:any, state:any, rootState: any, rootGetters :any}, request: CreatorInfoCreationRequest) {
            return new Promise((resolve, reject) => {
                rootGetters.facebookMarketingFactory.CreateAdAccountCreatorInfo(request)
                    .then((response : any) => {
                        if(response.status === 201) {
                            commit('setCreatorInfo', rootGetters.facebookMarketingFactory.MapCreatorInfo(response.data));
                        }
                        resolve(response);
                    }, (error : any) => {
                        reject(error);
                    })
            })
        }

    }
})
export default creatorStore;
