var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", { staticClass: "component-container" }, [
    _c("div", { staticStyle: { "max-width": "50rem" } }, [
      _c("div", { staticClass: "card mb-3 mt-3" }, [
        _c("div", { staticClass: "card-header" }, [
          _vm._v("\n                Manage Webhook\n            "),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "card-body" }, [
          _c("div", { staticClass: "form-row" }, [
            _c("div", { staticClass: "input-group mb-3" }, [
              _c(
                "button",
                {
                  staticClass: "btn btn-outline-secondary",
                  attrs: { type: "button", id: "button-addon1" },
                  on: { click: _vm.requestPurge },
                },
                [_vm._v("Purge Old Failed Webhook Leads")]
              ),
              _vm._v(" "),
              _c("input", {
                staticClass: "form-control",
                attrs: {
                  type: "text",
                  disabled: "",
                  placeholder: "",
                  "aria-label": "",
                  "aria-describedby": "button-addon1",
                },
                domProps: { value: _vm.responseMessage },
              }),
            ]),
          ]),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }