var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", {}, [
    _c("form", { staticClass: "needs-validation", attrs: { novalidate: "" } }, [
      _c("div", { staticClass: "display-5 mb-3 mt-3" }, [
        _vm._v("Import Ad Account from Facebook"),
      ]),
      _vm._v(" "),
      !_vm.needsRequiredProperties
        ? _c("div", { staticClass: "row" }, [
            _c("div", { staticClass: "col-sm-10" }, [
              _c("p", { staticClass: "m-1 mb-3" }, [
                _vm._v(
                  "Provide the company and an ad account id to import the account into the automation system. This can also be used to sync an account that is already part of the automation system."
                ),
              ]),
              _vm._v(" "),
              _c(
                "select",
                {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.company,
                      expression: "company",
                    },
                  ],
                  staticClass: "form-select",
                  attrs: { id: "company", required: "" },
                  on: {
                    change: function ($event) {
                      var $$selectedVal = Array.prototype.filter
                        .call($event.target.options, function (o) {
                          return o.selected
                        })
                        .map(function (o) {
                          var val = "_value" in o ? o._value : o.value
                          return val
                        })
                      _vm.company = $event.target.multiple
                        ? $$selectedVal
                        : $$selectedVal[0]
                    },
                  },
                },
                [
                  _c(
                    "option",
                    { attrs: { selected: "", disabled: "", value: "0" } },
                    [_vm._v("Please Select a Company")]
                  ),
                  _vm._v(" "),
                  _c("option", { attrs: { value: "1" } }, [_vm._v("CINC")]),
                  _vm._v(" "),
                  _c("option", { attrs: { value: "2" } }, [
                    _vm._v("Real Geeks"),
                  ]),
                ]
              ),
              _vm._v(" "),
              _vm.company != 0
                ? _c("div", { staticClass: "input-group mt-3" }, [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.adAccountId,
                          expression: "adAccountId",
                        },
                      ],
                      staticClass: "form-control",
                      attrs: {
                        type: "text",
                        placeholder: "Ad Account ID",
                        required: "",
                      },
                      domProps: { value: _vm.adAccountId },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) return
                          _vm.adAccountId = $event.target.value
                        },
                      },
                    }),
                    _vm._v(" "),
                    _c(
                      "button",
                      {
                        staticClass: "btn btn-outline-primary",
                        attrs: {
                          type: "button",
                          disabled: _vm.requestSubmitted,
                        },
                        on: { click: _vm.loadAdAccount },
                      },
                      [
                        _vm.requestSubmitted
                          ? _c("span", {
                              staticClass: "spinner-border spinner-border-sm",
                              attrs: { role: "status", "aria-hidden": "true" },
                            })
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.requestSubmitted
                          ? _c("span", { staticClass: "visually-hidden" }, [
                              _vm._v("Loading..."),
                            ])
                          : _vm._e(),
                        _vm._v(" "),
                        _c("span", [_vm._v("Submit")]),
                      ]
                    ),
                  ])
                : _vm._e(),
            ]),
          ])
        : _c("div", { staticClass: "row" }, [
            _c(
              "div",
              { staticClass: "col-sm-10" },
              [
                _vm._m(0),
                _vm._v(" "),
                _c("span", [_vm._v("* Required Properties * ")]),
                _vm._v(" "),
                _vm._l(_vm.requiredProperties, function (property, index) {
                  return _c("div", { key: index, staticClass: "mt-3" }, [
                    _vm._v(
                      "\n                    The " +
                        _vm._s(property.ObjectType) +
                        ' "' +
                        _vm._s(property.ObjectName) +
                        '"" requires a ' +
                        _vm._s(property.PropertyName) +
                        "\n                    "
                    ),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: property.Value,
                          expression: "property.Value",
                        },
                      ],
                      staticClass: "form-control",
                      attrs: {
                        type: "text",
                        placeholder: property.PropertyName,
                        required: "",
                      },
                      domProps: { value: property.Value },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) return
                          _vm.$set(property, "Value", $event.target.value)
                        },
                      },
                    }),
                  ])
                }),
                _vm._v(" "),
                _c(
                  "button",
                  {
                    staticClass: "btn btn-outline-primary mt-3",
                    attrs: { type: "button" },
                    on: { click: _vm.loadAdAccount },
                  },
                  [_vm._v("Submit")]
                ),
              ],
              2
            ),
          ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("div", { staticClass: "alert alert-warning" }, [
      _c("span", [
        _vm._v(
          "The supplied ad account was successfully found and can be imported but we need more detail."
        ),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }