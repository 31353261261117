import { Config } from "../models/Config";
import { IMarketingFactory } from "../interfaces/IMarketingFactory";
import store from "../store/";
import { router } from "../index";
import axios, { AxiosError } from 'axios';
import { AdAccountCreationRequest } from "../models/request/AdAccountCreationRequest";
import { CampaignCreationRequest } from "../models/request/CampaignCreationRequest";
import { AdsetCreationRequest } from "../models/request/AdsetCreationRequest";
import { AdCreationRequest } from "../models/request/AdCreationRequest";
import { AdAccountPermissionsRequest } from "../models/request/AdAccountPermissionsRequest";
import { CreatorInfoCreationRequest, FormCreationRequest } from "../models/FormCreationRequest";
import { AdTemplateUpdateRequest } from "../models/request/AdTemplateUpdateRequest";
import { ProductCatalogSet } from '../models/ProductCatalogSet';
import { Company } from "../enums/Company";
import { CreatorInfo, CreatorInfoAd, CreatorInfoAdset, CreatorInfoCampaign } from "../models/CreatorInfo";
import { ReportCreationRequest } from "../models/request/ReportCreationRequest";
import { AdTemplateCreateRequest } from "../models/request/AdTemplateCreateRequest";
import { ConfirmPageRequest } from "../models/request/ConfirmPageRequest";
import { CincPlatform } from "../models/CincPlatform";
import { ManagedPage } from "../models/ManagedPage";
import { UpdatePageRequest } from "../models/request/UpdatePageRequest";

export class FacebookMarketingFactory implements IMarketingFactory {
    private config : Config = require("../../config.json");
    private readonly apiUrl : string = this.config.apiUrl;

    constructor(authorization : string) {
        axios.defaults.headers.common["Authorization"] = authorization;
    }

    async SendReportRequest(reportRequest: ReportCreationRequest) {
        return axios
        .post(`${this.apiUrl}api/Report/Create`, reportRequest);
    }

    async CreateImageHash(byteString : string, adAccountId : string, company : Company) : Promise<any> {
        if (!adAccountId.includes('act_')) {
            adAccountId = 'act_' + adAccountId;
        }
        return axios
            .post(`${this.apiUrl}api/Image/`, { ImageBytes : byteString, AdAccountId: adAccountId, Company : company })
            .then(response => {
                return response.data;
            })
            .catch((error : any) => {
                this.factoryErrorHandling(error);
                throw new Error(error.response.data);
            });
    }

    async GrantAdAccountPermissions(request: AdAccountPermissionsRequest) : Promise<any> {
        var companyInt : number = +request.Company;
        return axios
            .post(`${this.apiUrl}api/Account/Configure/`, { AdAccountId : request.AdAccountId, Company: companyInt } )
            .then((response : any) => {
                return response;
            })
            .catch((error : any) => {
                this.factoryErrorHandling(error);
                throw new Error(error.response.data);
            });
    }

    async CreateCincConfiguredAdAccount(adAccountCreationRequest: AdAccountCreationRequest) : Promise<any> {
        return axios
            .post(`${this.apiUrl}api/Creator/AdAccount`, adAccountCreationRequest)
            .then((response : any) => {
                return response;
            })
            .catch((error : any) => {
                this.factoryErrorHandling(error);
                throw new Error(error.response.data);
            });
    }

    async CreateCincConfiguredCampaign(campaignCreationRequest: CampaignCreationRequest): Promise<string> {
        return axios
        .post(`${this.apiUrl}api/Creator/Campaign`, campaignCreationRequest)
        .then((response : any) => {
            return response;
        })
        .catch((error : any) => {
            this.factoryErrorHandling(error);
            throw new Error(error.response.data);
        });
    }

    async CreateCincConfiguredAdset(adsetCreationRequest: AdsetCreationRequest): Promise<string> {
        return axios
        .post(`${this.apiUrl}api/Creator/Adset`, adsetCreationRequest)
        .then((response : any) => {
            return response;
        })
        .catch((error : any) => {
            this.factoryErrorHandling(error);
            throw new Error(error.response.data);
        });
    }

    async CreateCincConfiguredAd(adCreationRequest: AdCreationRequest): Promise<string> {
        return axios
        .post(`${this.apiUrl}api/Creator/Ad`, adCreationRequest)
        .then((response : any) => {
            return response;
        })
        .catch((error : any) => {
            this.factoryErrorHandling(error);
            throw new Error(error.response.data);
        });
    }

    async CreateCincConfiguredForm(formCreationRequest: FormCreationRequest): Promise<string> {
        return axios
        .post(`${this.apiUrl}api/Creator/Form`, formCreationRequest)
        .then((response : any) => {
            return response;
        })
        .catch((error : any) => {
            this.factoryErrorHandling(error);
            throw new Error(error.response.data);
        });
    }

    async CreateAdAccountCreatorInfo(creatorInfoCreationRequest : CreatorInfoCreationRequest) : Promise<string> {
        return axios
        .post(`${this.apiUrl}api/Creator/CreatorInfo`, creatorInfoCreationRequest)
        .then((response : any) => {
            return response;
        })
        .catch((error : any) => {
            this.factoryErrorHandling(error);
            throw new Error(error.response.data);
        });
    }

    async ConfirmPageName(confirmPageRequest: ConfirmPageRequest) {
        return axios
        .post(`${this.apiUrl}api/Creator/ConfirmPage`, confirmPageRequest)
        .then((response : any) => {
            return response;
        })
        .catch((error : any) => {
            this.factoryErrorHandling(error);
            throw new Error(error.response.data);
        });
    }

    async ImportManagedPage(updateRequest: UpdatePageRequest) {
        return axios
        .post(`${this.apiUrl}api/Managed/ImportPage`, updateRequest)
        .then((response : any) => {
            return this.mapManagedPage(response.data);
        })
        .catch((error : any) => {
            this.factoryErrorHandling(error);
            throw new Error(error.response.data);
        });
    }
   
    //Get
    async GetExistingCreatorInfos() {
        return this.getMany(`api/Creator/CreatorInfos/`, this.mapDirectResponse);
    }
    async GetCreatorInfo(adAccountId: string) {
        return this.getOne(`api/Creator/CreatorInfo/`, adAccountId, this.MapCreatorInfo);
    }
    async GetAllManagedPages() {
        return this.getMany(`api/Managed/AllManagedPages`, this.mapManagedPage);
    }
    async GetCincPlatforms(search:string) {
        return this.getMany(`api/CincPlatform/Search/${search}`, this.mapCincPlatform);
    }
    async GetCincPlatform(id: string) {
        return this.getOne(`api/CincPlatform/`, id, this.mapCincPlatform);
    }
    async GetCompanyFacebookForms(fbPageId: string, company: Company) {
        return this.getMany(`api/Page/${fbPageId}/Forms/${company}`, this.mapDirectResponse);
    }
    async GetAdTemplates() {
        return this.getMany(`api/Template/AdText`, this.mapDirectResponse);
    }
    async GetLocationsBySearch(search: string) {
        return this.getMany(`api/Adset/Locations/${search}`, this.mapDirectResponse);
    }
    async GetProductSetsbyProductCatalogId(productCatalogRequest: ProductCatalogSet) {
        let params = new URLSearchParams(<Record<string, string>><unknown>productCatalogRequest).toString();
        return this.getMany(`api/ProductCatalog/ProductSets?${params}`, this.mapDirectResponse);
    }
    async GetFolderContent(prefix: string) {
        return this.getOne('api/Report', `?prefix=${prefix}`, this.mapDirectResponse);
    }



    //Download
    async DownloadFile(path: string) {
        return this.getOne('api/Report/Download', `?filePath=${path}`, this.mapDirectResponse);
    }
    
    //Delete
    async PurgeOldWebhookLeads() {
        return this.deleteAll(`api/webhook/PurgeOld`);
    }

    async DeleteAdTemplate(id :string) {
        return this.delete(`api/Template/AdText`, id);
    }

    //Update
    async UpdateAdTemplate(adTemplateUpdateRequest: AdTemplateUpdateRequest) {
        return axios
        .put(`${this.apiUrl}api/Template/AdText`, adTemplateUpdateRequest)
        .then((response : any) => {
            return response;
        })
        .catch((error : any) => {
            this.factoryErrorHandling(error);
            throw new Error(error.response.data);
        });
    }

    async UpdateManagedPage(updateRequest: UpdatePageRequest) {
        return axios
        .put(`${this.apiUrl}api/Creator/UpdatePage`, updateRequest)
        .then((response : any) => {
            return response;
        })
        .catch((error : any) => {
            this.factoryErrorHandling(error);
            throw new Error(error.response.data);
        });
    }

    async CreateAdTemplate(adTemplateCreateRequest: AdTemplateCreateRequest) {
        return axios
        .post(`${this.apiUrl}api/Template/AdText`, adTemplateCreateRequest)
        .then((response : any) => {
            return response;
        })
        .catch((error : any) => {
            this.factoryErrorHandling(error);
            throw new Error(error.response.data);
        });
    }

    //private verbs
    private getOne(edge : string, id: string, mappingFunction : Function) {
        return axios
            .get(`${this.apiUrl}${edge}${id}`)
            .then(response => {
                let data = response.data
                return mappingFunction(data);
            })
            .catch((error : any) => {
                this.factoryErrorHandling(error);
                throw new Error(error.response.data);
            });
    }

    private getMany(edge : string, mappingFunction : Function) {
        let result = axios
            .get(`${this.apiUrl}${edge}`)
            .then(response => {
                let returnData = response.data.map((element: any) => {
                    return element = mappingFunction(element);
                });
                return returnData;
            })
            .catch((error : any) => {
                this.factoryErrorHandling(error);
                throw new Error(error.response.data);
            });
        if(result) {
            return result;
        }
        return new Array();
    }

    async delete(edge : string, id: string) {
        return axios
            .delete(`${this.apiUrl}${edge}/${id}`)
            .then((response: any) => {
                return response.data;
            })
            .catch((error : any) => {
                this.factoryErrorHandling(error);
                throw new Error(error.response.data);
            });
    }

    async deleteAll(edge: string) : Promise<number> {
        return await axios
            .delete(`${this.apiUrl}${edge}`)
            .then((response: any) => {
                return response.data;
            })
            .catch((error : any) => {
                this.factoryErrorHandling(error);
                throw new Error(error.response.data);
            });
    }

    //Error Handling
    private factoryErrorHandling(error: AxiosError) : AxiosError {
        console.log(error);
        if(error?.response?.status === 401) { //unauthorized
            store.dispatch('logout');
            router.push({ name: 'login', params: { ErrorDisplayMessage: "Your session has expired or you are unauthorized. Please Login." }});
         }
         if (error?.response?.status === 504) { //gateway timeout
              error.response.data = "The request may have completed successfully, but the response was not received in time (30 sec). Please check the status of the request in the Facebook Ads Manager.";  
        }
        return error;
    }

    //Mapping
    public MapCreatorInfo(data : any) {
        let creatorInfo = new CreatorInfo();
        creatorInfo.AdAccountId = "act_"+data.adAccountId;
        switch (data.company) {
            case 'CINC':
                creatorInfo.Company = Company.CINC;
                break;
            case 'RealGeeks':
                creatorInfo.Company = Company.RealGeeks;
                break;
            default:
                creatorInfo.Company = Company.CINC;
        }
        creatorInfo.ClientFullName = data.clientFullName;   
        creatorInfo.DomainName = data.domainName;
        creatorInfo.States = data.states;
        creatorInfo.AdAccountName = data.adAccountName;
        creatorInfo.Campaigns = new Array<CreatorInfoCampaign>();
        if(data.campaigns !== null && data.campaigns !== undefined &&  data.campaigns.length > 0) {
            for(let campaignIndex = 0; campaignIndex < data.campaigns.length; campaignIndex++) {
                let campaign = new CreatorInfoCampaign();
                campaign.CampaignType = data.campaigns[campaignIndex].campaignType;
                campaign.CampaignName = data.campaigns[campaignIndex].campaignName;
                campaign.CampaignId = data.campaigns[campaignIndex].campaignId;
                campaign.Adsets = new Array<CreatorInfoAdset>();
                if(data.campaigns[campaignIndex].adsets !== null && data.campaigns[campaignIndex].adsets !== undefined &&  data.campaigns[campaignIndex].adsets.length > 0) {
                    for(let adsetIndex = 0; adsetIndex < data.campaigns[campaignIndex].adsets.length; adsetIndex++) {
                        let adset = new CreatorInfoAdset();
                        adset.AdsetId = data.campaigns[campaignIndex].adsets[adsetIndex].adsetId;
                        adset.AdsetName = data.campaigns[campaignIndex].adsets[adsetIndex].adsetName;
                        adset.PageId = data.campaigns[campaignIndex].adsets[adsetIndex].pageId;
                        adset.ProductSetId = data.campaigns[campaignIndex].adsets[adsetIndex].productSetId;
                        adset.Ads = new Array<CreatorInfoAd>();
                        if(data.campaigns[campaignIndex].adsets[adsetIndex].ads !== null && data.campaigns[campaignIndex].adsets[adsetIndex].ads !== undefined &&  data.campaigns[campaignIndex].adsets[adsetIndex].ads.length > 0) {
                            for(let adIndex = 0; adIndex < data.campaigns[campaignIndex].adsets[adsetIndex].ads.length; adIndex++) {
                                let ad = new CreatorInfoAd();
                                ad.AdId = data.campaigns[campaignIndex].adsets[adsetIndex].ads[adIndex].adId;
                                ad.AdName = data.campaigns[campaignIndex].adsets[adsetIndex].ads[adIndex].adName;
                                adset.Ads.push(ad);
                            }
                        }
                        campaign.Adsets.push(adset);
                    }
                }
                creatorInfo.Campaigns.push(campaign);
            }
        }
        return creatorInfo;
    }

    mapCincPlatform(data: any) {
        let cincPlatform = new CincPlatform(); 
        cincPlatform.Id = data.id;
        cincPlatform.DomainName = data.domainName;
        cincPlatform.Status = data.status;
        cincPlatform.CompanyName = data.companyName;
        cincPlatform.ContactFirstName = data.contactFirstName;
        cincPlatform.ContactLastName = data.contactLastName;
        cincPlatform.ContactAddress = data.contactAddress;
        cincPlatform.ContactCity = data.contactCity;
        cincPlatform.ContactState = data.contactState;
        cincPlatform.ContactZip = data.contactZip;
        cincPlatform.ContactPhone = data.contactPhone;
        cincPlatform.Country = data.country;
        return cincPlatform;
    }

    mapManagedPage(data: any) : ManagedPage {
        let managedPage = new ManagedPage();
        managedPage.PageID = data.pageID;
        managedPage.PageName = data.pageName;
        managedPage.DDID = data.ddid;
        managedPage.DomainName = data.domainName;
        managedPage.About = data.about;
        managedPage.Phone = data.phone;
        managedPage.Website = data.website;
        managedPage.DomainStatus = data.domainStatus;
        return managedPage;
    }

    private mapDirectResponse(data: any) {
        return data;
    }
}