import { IAdState } from '../Interfaces/IAdState';
import { Company } from "../../enums/Company";
import { AdTemplate } from '../../models/AdTemplate';
import { AdTemplateUpdateRequest } from '../../models/request/AdTemplateUpdateRequest';
import { AdTemplateCreateRequest } from '../../models/request/AdTemplateCreateRequest';

const adStore = ({
    namespaced: true,
    state: {
        Templates: new Array<AdTemplate>(),
    } as IAdState,
    mutations: {
        putTemplates(state : IAdState, templates : AdTemplate[]) {
            state.Templates = templates;
        }
    },
    actions: {
        async getAllTemplates ({commit, state, rootState, rootGetters} : {commit:any, state:any, rootState: any, rootGetters :any}, hardUpdate: boolean) {
            if(hardUpdate) {
                state.Templates = new Array<AdTemplate>();
            }

            if(state.Templates === undefined || state.Templates.length === 0) {
                await rootGetters.facebookMarketingFactory.GetAdTemplates()
                .then((templates : AdTemplate[]) => {
                    commit('putTemplates', templates);
                });
            }
            return state.Templates;
        },
        async updateTemplate({commit, state, rootState, rootGetters} : {commit:any, state:any, rootState: any, rootGetters :any}, updateRequest : AdTemplateUpdateRequest) {
            await rootGetters.facebookMarketingFactory.UpdateAdTemplate(updateRequest)
            .then((response : any) => {
                return response;
            });
        },
        
        async createTemplate({commit, state, rootState, rootGetters} : {commit:any, state:any, rootState: any, rootGetters :any}, createRequest : AdTemplateCreateRequest) {
            await rootGetters.facebookMarketingFactory.CreateAdTemplate(createRequest)
            .then((response : any) => {
                return response;
            });
        },
        async deleteTemplate({commit, state, rootState, rootGetters} : {commit:any, state:any, rootState: any, rootGetters :any}, templateId : string) {
            await rootGetters.facebookMarketingFactory.DeleteAdTemplate(templateId)
            .then((response : any) => {
                return response;
            });
        },
        createImageHash ({commit, state, rootState, rootGetters} : {commit:any, state:any, rootState: any, rootGetters :any}, imageObj: {byteString: string, adAccountId: string, company: Company}) {
            return new Promise ((resolve, reject) => {
                rootGetters.facebookMarketingFactory.CreateImageHash(imageObj.byteString, imageObj.adAccountId, imageObj.company)
                .then((response : any) => {
                    resolve(response);
                }, (error : any) => {
                    reject(error);
                })
            })
        }
    }
})

export default adStore