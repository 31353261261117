import { FacebookObject } from "./FacebookObject";
import { ValidationResponse, PropertyError } from "./ValidationResponse";

export class Ad extends FacebookObject {
    adAccountId : string = "";
    campaignId : string = "";
    formId : string = "";
    pageId : string = "";
    adsetId : string = "";
    body : string = "";
    linkDescription : string = "";
    linkHeadline : string = "";
    callToAction : string = "LEARN_MORE";
    fbImageHash : string = "";
    imageUrl : string = "";
    linkUrl : string = "";
    displayUrl : string = "";
    effectiveStatus : string = "";
    status = "PAUSED";

    private readonly MAX_HEADLINE_LENGTH : number = 150;
    private readonly MAX_DESCRIPTION_LENGTH : number = 200;
    private readonly MAX_BODY_LENGTH : number = 500;

    public static readonly HeadlineVal : string = "linkHeadline";
    public static readonly DescriptionVal : string = "linkDescription";
    public static readonly BodyVal : string = "body";
    public static readonly CallToActionVal : string = "cta";
    public static readonly FormIdVal : string = "leadForm";
    public static readonly ImageVal : string = "image";
    public static readonly LinkUrlVal : string = "linkUrl";
    public static readonly DisplayUrlVal : string = "displayUrl";

    Validate(): ValidationResponse {
        let validationResponse = new ValidationResponse();
        
        this.checkPropertyError(this.nameValidation(), validationResponse);
        this.checkPropertyError(this.linkHeadlineValidation(), validationResponse);
        this.checkPropertyError(this.linkDescriptionValidation(), validationResponse);
        this.checkPropertyError(this.bodyValidation(), validationResponse);
        this.checkPropertyError(this.ctaValidation(), validationResponse);
        this.checkPropertyError(this.formValidation(), validationResponse);
        this.checkPropertyError(this.imageValidation(), validationResponse);
        this.checkPropertyError(this.linkUrlValidation(), validationResponse);
        this.checkPropertyError(this.displayUrlValidation(), validationResponse);

        validationResponse.IsValid = validationResponse.ErrorMessages.length === 0;
        return validationResponse;
    }

    linkUrlValidation() : PropertyError {
        if(this.linkUrl === "") {
            return new PropertyError(Ad.LinkUrlVal, "Destination Url is Required");
        }
        return new PropertyError("", "");
    }
    displayUrlValidation() : PropertyError {
        if(this.displayUrl === "") {
            return new PropertyError(Ad.DisplayUrlVal, "Display Url is Required");
        }
        let domainRegex = new RegExp("^[a-zA-Z0-9]+\\.[a-zA-Z0-9]+(\\.[a-zA-Z0-9]+)?");
        if(!domainRegex.test(this.displayUrl)) {
            return new PropertyError(Ad.DisplayUrlVal, "Display Url must be a valid domain that has a two piece domain (e.g. cincpro.com, cincpro.edu, cincpro.org)");
        }
        return new PropertyError("", "");
    }
    linkHeadlineValidation() : PropertyError {
        if(this.linkHeadline === "") {
            return new PropertyError(Ad.HeadlineVal, "Headline is Required");
        }
        if(this.linkHeadline.length > this.MAX_HEADLINE_LENGTH) {
            return new PropertyError(Ad.HeadlineVal, `Headline cannot be more than ${this.MAX_HEADLINE_LENGTH} characters`);
        }
        return  new PropertyError("", "");
    }
    linkDescriptionValidation() : PropertyError {
        if(this.linkDescription === "") {
            return new PropertyError(Ad.DescriptionVal, "Description is Required");
        }  
        if(this.linkDescription.length > this.MAX_DESCRIPTION_LENGTH) {
            return new PropertyError(Ad.DescriptionVal, `Description cannot be more than ${this.MAX_DESCRIPTION_LENGTH} characters`);
        }
        return new PropertyError("","");
    }
    bodyValidation() : PropertyError {
        if(this.body === "") {
            return new PropertyError(Ad.BodyVal, "Primary Text is Required");
        }  
        if(this.body.length > this.MAX_BODY_LENGTH) {
            return new PropertyError(Ad.BodyVal, `Primary Text cannot be more than ${this.MAX_BODY_LENGTH} characters`);
        }
        return new PropertyError("", "");
    }
    ctaValidation() : PropertyError {
        return (this.callToAction === "") ? new PropertyError(Ad.CallToActionVal, "Call to Action is Required") : new PropertyError("","");
    }
    formValidation() : PropertyError {
        return (this.formId === "") ? new PropertyError(Ad.FormIdVal, "Lead Form is Required") : new PropertyError("","");
    }
    imageValidation() : PropertyError {
        if(this.imageUrl === "" && this.fbImageHash === "") {
            return new PropertyError(Ad.ImageVal, "An Image URL or Upload is Required");
        }
        if(this.imageUrl !== "" && this.fbImageHash !== "") {
            return new PropertyError(Ad.ImageVal, "An ad cannot have both an Image URL and Upload, please submit one")
        }
        return new PropertyError("","");
    }

    public static CreateCopy(original : Ad) : Ad {
        let copy = new Ad();
        copy.id = original.id;
        copy.name = original.name;
        copy.status = original.status;
        copy.adAccountId = original.adAccountId;
        copy.campaignId = original.campaignId;
        copy.formId = original.formId;
        copy.pageId = original.pageId;
        copy.adsetId = original.adsetId;
        copy.body = original.body;
        copy.linkDescription = original.linkDescription;
        copy.linkHeadline = original.linkHeadline;
        copy.callToAction = original.callToAction;
        copy.fbImageHash = original.fbImageHash;
        copy.imageUrl = original.imageUrl;
        copy.effectiveStatus = original.effectiveStatus;
        return copy;
    }
}