<template>
     <div class="component-container">
        <div style="max-width: 50rem;">
            <div class="card mb-3 mt-3" >
                <div class="card-header">
                    Manage Webhook
                </div>
                <div class="card-body">
                    <div class="form-row">
                        <div class="input-group mb-3">
                            <button class="btn btn-outline-secondary" @click="requestPurge" type="button" id="button-addon1">Purge Old Failed Webhook Leads</button>
                            <input type="text" disabled class="form-control" :value="responseMessage" placeholder="" aria-label="" aria-describedby="button-addon1">
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import { Vue, Component } from 'vue-property-decorator';

@Component({
    name: 'Webhook'
})
export default class Webhook extends Vue {
    responseMessage: string = "";

    async created() {

    }   
    async requestPurge() {
        await this.$store.dispatch('admin/purgeOldWebhookLeads')
        .then((response : any) => {
            this.responseMessage = `Success, ${response} records purged.`
        })
        .catch((error : string) => {
            this.responseMessage = `Failure, ${error}`
        });
    }
}
</script>
