var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "aside",
    { staticClass: "sidebar d-flex flex-column flex-shrink-0 p-1 bg-light" },
    [
      _c("ul", { staticClass: "nav nav-pills flex-column mb-auto" }, [
        _c(
          "li",
          { staticClass: "nav-item" },
          [
            _vm.creatorInfo.AdAccountName &&
            _vm.creatorInfo.AdAccountName.trim() !== ""
              ? _c(
                  "router-link",
                  {
                    staticClass: "nav-link",
                    class: {
                      "active-link":
                        _vm.$store.state.creator.activeItem ===
                        _vm.creatorInfo.AdAccountName,
                    },
                    attrs: { to: "#", exact: "" },
                    nativeOn: {
                      click: function ($event) {
                        $event.preventDefault()
                        return _vm.navigateToCampaign(
                          _vm.creatorInfo.AdAccountName
                        )
                      },
                    },
                  },
                  [
                    _c("font-awesome-icon", {
                      attrs: { icon: ["far", "address-card"] },
                    }),
                    _vm._v(
                      " \n        " +
                        _vm._s(_vm.creatorInfo.AdAccountName) +
                        "\n      "
                    ),
                  ],
                  1
                )
              : _vm._e(),
            _vm._v(" "),
            _vm._l(_vm.creatorInfo.Campaigns, function (campaign, index) {
              return _c(
                "ul",
                {
                  key: "campaign-" + index,
                  staticClass: "nav nav-pills flex-column indent-list",
                },
                [
                  _c(
                    "router-link",
                    {
                      staticClass: "nav-link",
                      class: {
                        "active-link":
                          _vm.$store.state.creator.activeItem ===
                          campaign.CampaignId,
                      },
                      attrs: { to: "#", exact: "" },
                      nativeOn: {
                        click: function ($event) {
                          $event.preventDefault()
                          return _vm.navigateToAdset(campaign.CampaignId)
                        },
                      },
                    },
                    [
                      _c("font-awesome-icon", {
                        attrs: { icon: ["far", "folder"] },
                      }),
                      _vm._v(
                        "\n          " +
                          _vm._s(campaign.CampaignName) +
                          "\n        "
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "ul",
                    { staticClass: "nav nav-pills flex-column indent-list" },
                    _vm._l(
                      _vm.creatorInfoAdsets(campaign),
                      function (adset, adsetIndex) {
                        return _c(
                          "li",
                          {
                            key: "adset-" + adsetIndex,
                            staticClass: "nav-item",
                          },
                          [
                            _c(
                              "router-link",
                              {
                                staticClass: "nav-link",
                                class: {
                                  "active-link":
                                    _vm.$store.state.creator.activeItem ===
                                    adset.AdsetId,
                                },
                                attrs: { to: "#", exact: "" },
                                nativeOn: {
                                  click: function ($event) {
                                    $event.preventDefault()
                                    return _vm.navigateToForm(
                                      campaign.CampaignId,
                                      adset.AdsetId
                                    )
                                  },
                                },
                              },
                              [
                                _c("font-awesome-icon", {
                                  attrs: { icon: "border-all" },
                                }),
                                _vm._v(
                                  "\n              " +
                                    _vm._s(adset.AdsetName) +
                                    "\n            "
                                ),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            adset.Ads && adset.Ads.length > 0
                              ? _c(
                                  "ul",
                                  {
                                    staticClass:
                                      "nav nav-pills flex-column indent-list",
                                  },
                                  _vm._l(
                                    _vm.creatorInfoAds(adset),
                                    function (ad, adIndex) {
                                      return _c(
                                        "li",
                                        {
                                          key: "ad-" + adIndex,
                                          staticClass: "nav-item",
                                        },
                                        [
                                          _c("font-awesome-icon", {
                                            attrs: { icon: "ad" },
                                          }),
                                          _vm._v(
                                            "\n                " +
                                              _vm._s(ad.AdName) +
                                              "\n              "
                                          ),
                                        ],
                                        1
                                      )
                                    }
                                  ),
                                  0
                                )
                              : _vm._e(),
                          ],
                          1
                        )
                      }
                    ),
                    0
                  ),
                ],
                1
              )
            }),
          ],
          2
        ),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }