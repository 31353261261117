import { Company } from "../../enums/Company";

const leadFormStore = ({
    namespaced: true,
    actions: {
        async getCompanyFacebookForms ({rootState}: {rootState: any}, formRequestObj: { fbPageId: string, company : Company }) {
            return new Promise ((resolve, reject) => {
                rootState.facebookMarketingFactory.GetCompanyFacebookForms(formRequestObj.fbPageId, formRequestObj.company)
                .then((response : any) => {
                    resolve(response);
                }, (error : any) => {
                    reject(error);
                })
            })
        }
    }
})

export default leadFormStore