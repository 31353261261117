import Vue from "vue";
import App from './App.vue';
import VueRouter from 'vue-router';
import '../style/site.scss'; // each css file must be included to ensure webpack picks it up and puts it on the index.html upon bundling
import AdAccountCreator from "./components/creator/AdAccount.vue";
import CampaignCreator from "./components/creator/Campaign.vue";
import AdsetCreator from "./components/creator/Adset.vue";
import Ad from "./components/creator/Ad.vue";
import FormCreator from "./components/creator/Form.vue";
import TokenManager from "./components/TokenManager.vue";
import Login from "./components/Login.vue";
import Webhook from "./components/admin/Webhook.vue";
import store from './store';
import ToastedPlugin from './plugins/Toasted';
import { library } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { fas } from '@fortawesome/free-solid-svg-icons'
import { far } from '@fortawesome/free-regular-svg-icons'
import InfoLoader from "./components/creator/InfoLoader.vue";
import ExistingReports from "./components/report/ExistingReports.vue";
import ReportBuilder from "./components/report/ReportBuilder.vue";
import AdTemplate from "./components/editor/AdTemplate.vue";
import PageEditor from "./components/editor/Page.vue";

library.add(fas, far);
Vue.component('font-awesome-icon', FontAwesomeIcon);

export const router = new VueRouter({
    mode: 'history',
    routes: [
      { path: '/', component: Login, name: "login", props: true},
      { path: '/login', component: Login, name: "login", props: true},
      { path: '/tokenmanager', component: TokenManager, name: "tokenManager" },
      { path: '/facebook/creator/adaccount', meta: { requiresAuth: true }, component: AdAccountCreator },
      { path: '/facebook/creator/campaign', meta: { requiresAuth: true }, component: CampaignCreator },
      { path: '/facebook/creator/adset', meta: { requiresAuth: true }, component: AdsetCreator },
      { path: '/facebook/creator/ad', meta: { requiresAuth: true }, component: Ad, name: "adCreator"},
      { path: '/facebook/creator/form', meta: { requiresAuth: true }, component: FormCreator, },
      { path: '/facebook/creator/infoloader', meta: { requiresAuth: true }, component: InfoLoader, name: "infoLoader" },
      { path: '/admin/webhook', meta: { requiresAuth: true }, component: Webhook},
      { path: '/facebook/reports/existing', meta: {requiresAuth: true }, component: ExistingReports, name: "existingReports"},
      { path: '/facebook/reports/create', meta: {requiresAuth: true }, component: ReportBuilder, name: "reportBuilder"},
      { path: '/facebook/editor/adtemplate', meta: {requiresAuth: true }, component: AdTemplate, name: "adTemplate" },
      { path: '/facebook/editor/page', meta: {requiresAuth: true }, component: PageEditor, name: "pageEditor" }
    ],
    //realigns scroll to top of the screen on view change
    scrollBehavior(to, from, savedPosition) {
      return { x :0, y:0 }
    }
});

Vue.use(VueRouter);
Vue.use(ToastedPlugin);

router.beforeEach(async (to, from, next) => {
  var isAuthenticated = await store.dispatch('isAuthenticated');
  if(to.meta && to.meta.requiresAuth && !isAuthenticated) {
    next({ path: '/login'});
  }
  else if(to.path === '/' && isAuthenticated) {
    next({ path: '/facebook/creator/adaccount' })
  }
  next();
});

let v = new Vue({
    render: h=> h(App),
    router: router,
    store: store
}).$mount('#app');
