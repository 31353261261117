<template>
    <div class="">
        <form class="needs-validation" novalidate>
            <div class="display-5 mb-3 mt-3">Ad Account Permissions Granter</div>
                <div class="col-sm-10">
                    <p class="m-1 mb-3">This tool is used to grant our Facebook Marketing team permissions to manage an account by Ad Account ID.</p>
                    <select v-model="company" class="form-select" id="company" required>
                        <option selected disabled value=0>Please Select a Company</option>
                        <option value=1>CINC</option>
                        <option value=2>Real Geeks</option>
                    </select>
                    <div v-if="company != 0" class="input-group mt-3">
                    <input id="adAccountId" type="text" class="form-control" :class="isValid ? '' : 'is-invalid'" placeholder="Ad Account Id" v-model=AdAccountId />
                    <button class="btn btn-outline-primary" type="button" @click=clickGrant :disabled="RequestSubmitted">
                        <span v-if="RequestSubmitted" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                        <span v-if="RequestSubmitted" class="visually-hidden">Loading...</span>
                        <span>Grant</span>
                    </button>
                </div>
                <div v-if="!isValid" class="row text-danger mt-1">
                    <div class="col">
                        <div class="alert alert-danger" role="alert" v-html=ValidationFeedback></div>
                    </div>
                </div>
                <div v-if="isSuccessful" class="row text-success mt-1">
                    <div class="col">
                        <div class="alert alert-success" role="alert" v-html=SuccessFeedback></div>
                    </div>
                </div>
            </div>
        </form>
    </div>
</template>

<script lang="ts">
import { Vue, Component } from 'vue-property-decorator';
import { Company } from '../../../enums/Company';
import { AdAccountPermissionsRequest } from '../../../models/request/AdAccountPermissionsRequest';

@Component
export default class PermissionsGranter extends Vue {
    AdAccountId: string = "";
    ValidationFeedback: string = "";
    SuccessFeedback: string = "";
    RequestSubmitted: boolean = false;
    company: Company = Company.None;

    get isValid() {
        return this.ValidationFeedback === "";
    }
    get isSuccessful() {
        return this.SuccessFeedback !== "";
    }

    clickGrant() {
        this.SuccessFeedback = "";
        this.ValidationFeedback = "";

        let actExp = /^act_/i;
        let numericExp = /^\d+$/;
        if(actExp.test(this.AdAccountId) && numericExp.test(this.AdAccountId.substring(4))) {
            this.requestGrant(this.AdAccountId);
        }
        else if(numericExp.test(this.AdAccountId)) {
            this.requestGrant(`act_${this.AdAccountId}`);
        }
        else {
            this.ValidationFeedback = "Invalid Ad Account Id"
        }
    }

    private requestGrant(adAccountId: string) {
        this.ValidationFeedback = "";
        this.RequestSubmitted = true;
        var accountPermissionsRequest = new AdAccountPermissionsRequest();
        accountPermissionsRequest.AdAccountId = adAccountId;
        accountPermissionsRequest.Company = this.company;
        this.$store.getters["facebookMarketingFactory"].GrantAdAccountPermissions(accountPermissionsRequest)
            .then((response : any) => {
                if(response.status === 207)
                {
                    this.ValidationFeedback = `Partial Success (Some part of the process succeeded): <br/> `;
                    response.data.forEach((value: string) => {
                        this.ValidationFeedback += `${value} <br/>`;
                    });
                } 
                else {
                    this.SuccessFeedback = `The Ad Account with the ID: '${adAccountId.substring(4)}' was successfully updated.`;
                }
            })
            .catch((error : string) => {
                this.ValidationFeedback = error.toString().replace(/,/g, '<br />');
            })
            .finally(() => {
                this.RequestSubmitted = false;
            });
    }
}
</script>../../../models/request/AdAccountPermissionsRequest