const adminStore = ({
    namespaced: true,
    getters: {},
    mutations: {},
    actions: {
        async purgeOldWebhookLeads({commit, state, rootState, rootGetters}: {commit:any, state:any, rootState:any, rootGetters:any}) {
            return await rootGetters.facebookMarketingFactory.PurgeOldWebhookLeads()
                .then((response: any) => {
                    return response;
                })
                .catch((error: any) => {
                    return error;
                })
        }
    }
})

export default adminStore;