import { ConfirmPageRequest } from "../../models/request/ConfirmPageRequest";

const pageStore = ({
    namespaced: true,
    actions: {
        async confirmPageName({commit, rootState, rootGetters} : {commit:any, state:any, rootState: any, rootGetters :any}, confirmPageRequest : ConfirmPageRequest) {
            return new Promise((resolve, reject) => {
                rootGetters.facebookMarketingFactory.ConfirmPageName(confirmPageRequest)
                .then((response : any) => {
                    resolve(response);
                }, (error : any) => {
                    reject(error);
                })
            })
        }
    }
})
export default pageStore;