import { City } from './City';
import { Region } from './Region';
import { County } from './County';

export class SearchLocationResult {
    public key! : string;
    public name : string = "";
    public country_code : string = "";
    public region: string = "";
    public region_id: string = "";
    public type: string= "";
}

export class LocationSelectorResults {
    public regions : Region[] = new Array();
    public cities : City[] = new Array();
    public counties : County[] = new Array();
    public locationTypes : string[] = new Array();
}