var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "component-container" },
    [
      _c("div", { staticClass: "display-5 mb-3 mt-3" }, [
        _vm._v("Creator Info Loader"),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "row" }, [
        _c("div", { staticClass: "col-sm-10" }, [
          _c("p", { staticClass: "m-1 mb-3" }, [
            _vm._v(
              "Load an ad account into the creator that has already been created or imported into the system."
            ),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "input-group mb-3 mt-1" }, [
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.filterInput,
                  expression: "filterInput",
                },
              ],
              staticClass: "form-control",
              attrs: {
                type: "text",
                placeholder: "Search by Ad Account Name or ID",
              },
              domProps: { value: _vm.filterInput },
              on: {
                input: function ($event) {
                  if ($event.target.composing) return
                  _vm.filterInput = $event.target.value
                },
              },
            }),
            _vm._v(" "),
            _c(
              "span",
              { staticClass: "input-group-text" },
              [
                _c("font-awesome-icon", {
                  attrs: { icon: ["fas", "magnifying-glass"] },
                }),
              ],
              1
            ),
          ]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "list-group" },
            _vm._l(_vm.filteredExistingCreatorInfos, function (creatorInfo) {
              return _c(
                "a",
                {
                  key: creatorInfo.id,
                  staticClass:
                    "list-group-item list-group-item-action d-flex justify-content-between align-items-start",
                  attrs: { href: "#" },
                  on: {
                    click: function ($event) {
                      return _vm.loadCreatorInfo(creatorInfo.id)
                    },
                  },
                },
                [
                  _c("div", { staticClass: "ms-1 me-auto" }, [
                    _c("h6", [_vm._v(_vm._s(creatorInfo.name))]),
                    _vm._v(" "),
                    _c("small", { staticClass: "text-muted" }, [
                      _vm._v(_vm._s(creatorInfo.id)),
                    ]),
                  ]),
                ]
              )
            }),
            0
          ),
        ]),
      ]),
      _vm._v(" "),
      _c("h4", { staticClass: "display-6 m-5" }, [_vm._v(" - Or - ")]),
      _vm._v(" "),
      _c("AdAccountLoader"),
      _vm._v(" "),
      _c("h4", { staticClass: "display-6 m-5" }, [_vm._v(" - Or - ")]),
      _vm._v(" "),
      _c("PermissionsGranter"),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }