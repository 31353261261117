import { ManagedPage } from "../../models/ManagedPage";
import { ImportPageRequest } from "../../models/request/ImportPageRequest";
import { UpdatePageRequest } from "../../models/request/UpdatePageRequest";
import { IManagedPageState } from "../Interfaces/IManagedPageState";

const managedPageStore = ({
    namespaced: true,
    state: {
        managedPages: new Array()
    } as IManagedPageState,
    actions: {
        async getAllManagedPages({commit, state, rootState, rootGetters} : {commit:any, state:any, rootState: any, rootGetters :any}) {
            if(state.managedPages === undefined || state.managedPages === null || state.managedPages.length === 0) {
                await rootGetters.facebookMarketingFactory.GetAllManagedPages()
                .then((response : any) => {
                    commit('setManagedPages', response);
                });
            }
            return state.managedPages;
        },
        async updateManagedPage({commit, rootState, rootGetters} : {commit:any, state:any, rootState: any, rootGetters :any}, updateRequest : UpdatePageRequest) {
            return new Promise((resolve, reject) => {
                rootGetters.facebookMarketingFactory.UpdateManagedPage(updateRequest)
                .then((response : any) => {
                    resolve(response);
                }, (error : any) => {
                    reject(error);
                })
            });
        },
        async importManagedPage({commit, rootState, rootGetters} : {commit:any, state:any, rootState: any, rootGetters :any}, importRequest : ImportPageRequest) {
            return new Promise((resolve, reject) => {
                rootGetters.facebookMarketingFactory.ImportManagedPage(importRequest)
                .then((response : any) => {
                    commit(`addManagedPage`, response);
                    resolve(response);
                }, (error : any) => {
                    reject(error);
                })
            });
        }
    },
    mutations: {
        setManagedPages(state: any, managedPages: ManagedPage[]){
            state.managedPages = managedPages;
        },
        addManagedPage(state: any, managedPage: ManagedPage) {
            state.managedPages.push(managedPage);
        }
    }
})
export default managedPageStore;