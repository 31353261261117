
const locationStore = ({
    namespaced: true,
    actions: {
        async getLocationsBySearch ({rootState}: {rootState: any}, search: string) {
            return new Promise ((resolve, reject) => {
                rootState.facebookMarketingFactory.GetLocationsBySearch(search)
                .then((response : any) => {
                    resolve(response);
                }, (error : any) => {
                    reject(error);
                })
            })
        }
    }
})

export default locationStore