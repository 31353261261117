<template>
    <div class="component-container">
        <PageManager />
    </div>
</template>


<script lang="ts">
import { Vue, Component } from 'vue-property-decorator';
import PageManager from '../creator/component/PageManager.vue';
@Component({
    name: 'PageEditor',
    components: {
        PageManager
    }
})
export default class PageEditor extends Vue {

    
}
</script>