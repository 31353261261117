import { IFacebookObject } from "../interfaces/IFacebookObject";
import { ValidationResponse, PropertyError } from "./ValidationResponse";

export abstract class FacebookObject implements IFacebookObject {
    public id: string = "";    
    public name: string = "";
    public status: string = "";

    public static readonly NameVal : string = "name";

    abstract Validate(): ValidationResponse;

    protected nameValidation() : PropertyError {
        return (this.name === "") ? new PropertyError(FacebookObject.NameVal, "Name is Required") : new PropertyError("","");
    }

    protected checkPropertyError(propError : PropertyError, response: ValidationResponse) {
        if(propError.error !== "") {
            response.ErrorMessages.push(propError);
        }
    }
}