export class ManagedPage {
    PageID: string = ``;
    PageName: string = '';
    DDID: string = '';
    DomainName: string = '';
    DomainStatus: string = "";
    About: string = '';
    Phone: string = '';
    Website: string = '';
    HasWebhook: boolean = false;
}