import { Ad } from "./Ad";
import { City } from './City';
import { FacebookObject } from "./FacebookObject";
import { ValidationResponse, PropertyError } from "./ValidationResponse";

export class Adset extends FacebookObject {
    campaignId: string = "";
    createDate!: Date;
    Ads: Ad[] = new Array();
    AdsLoaded: boolean = false;
    fbPageId : string = "";
    startTimeUtc?: Date = undefined;
    endTimeUtc?: Date = undefined;
    locationTypes : string[] = new Array();
    cities: City[] = new Array();
    regions : any = new Array();
    regionIds : string[] = new Array();
    adAccountId! : string;
    optimizationGoal : string = "LEAD_GENERATION";
    interestIds : string[] = new Array();
    audienceIds : string[] = new Array();
    locales : number[] = [6]; //default value of "English (US)"
    isDetailed: boolean = false;

    public static readonly FacebookPageVal : string = "fbPageId";
    public static readonly InterestsVal : string = "interestIds";
    public static readonly LocationsVal : string = "locations";

    Validate(): ValidationResponse {
        let validationResponse = new ValidationResponse();

        this.checkPropertyError(this.nameValidation(), validationResponse);
        this.checkPropertyError(this.facbookPageValidation(), validationResponse);
        this.checkPropertyError(this.interestValidation(), validationResponse);
        this.checkPropertyError(this.locationValidation(), validationResponse);
        
        validationResponse.IsValid = validationResponse.ErrorMessages.length === 0;
        return validationResponse;
    }

    facbookPageValidation() : PropertyError {
        return (this.fbPageId === "") ? new PropertyError(Adset.FacebookPageVal, "Facebook Page is Required") : new PropertyError("","");
    }
    interestValidation() : PropertyError {
        return (this.interestIds.length === 0) ? new PropertyError(Adset.InterestsVal, "Interests selection is required") : new PropertyError("","");
    }
    locationValidation() : PropertyError {
        if(this.regionIds.length === 0 && this.cities.length === 0) {
            return new PropertyError(Adset.LocationsVal, "Location selection is required")
        }
        if(this.cities.length > 0) {
            if(this.cities.findIndex((city) => { return !(city.radius >= 15 && city.radius <= 50); }) > -1)
            {
                return new PropertyError(Adset.LocationsVal, "All cities must have a distance between 15 and 50");
            }
        }
        return new PropertyError("","");
    }
}


