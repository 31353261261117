export class AdTemplate{
    id!: string;
    order!: number;
    templateName!: string;
    adName!: string;
    templateType!: number;
    displayType!: number;
    language!: number;
    headline!: string;
    description!: string;
    primaryText!:string;
}