<template>
    <div class="d-flex">
        <Sidebar/>
        <div class="component-container-sidebar">
            <h6 class="ms-3 mt-3">Account ID: {{ creatorInfo.AdAccountId.substring(4) }}</h6>
            <h6 class="ms-3">Campaign ID: {{ selectedCampaign.CampaignId }}</h6>
            <h6 class="ms-3">Ad Set ID: {{ selectedAdSet.AdsetId }}</h6>
            <div class="card mb-3 mt-3" >
                <div class="card-header">
                    Ad Creator
                </div>
                <div class="card-body">
                    <form v-if=!displaySuccess class="needs-validation" novalidate>
                        <div class="container">
                            <div class="row mt-3">
                                <div class="col-sm-12">
                                    <div class="form-group mb-2">
                                        <label for="adTemplates">Ad Templates</label>
                                        <select :disabled="adTemplates.length === 0" class="form-select" id="adTemplates" v-model="selectedTemplateId" @change=updateAdTextFields>
                                            <option value="">Please Select an Ad Template</option>
                                            <option v-for="adTemplate in adTemplates" :key=adTemplate.id :value=adTemplate.id>{{adTemplate.templateName}}</option>
                                        </select>
                                    </div>
                                    <div class="form-group mb-2">
                                        <label for="adName">Ad Name</label>
                                        <input type="text" id="adName" class="form-control" v-model="adName">
                                        <p class="validation" v-text="nameValidation"  v-if="nameValidation !== ''"></p>
                                    </div>
                                    <div v-if="placeholders.length > 0">
                                        <div class="form-group mb-2" v-for="placeholder in placeholders" v-bind:key="placeholder.name">
                                            <label :for="placeholder.name">{{placeholder.name}}</label>
                                            <input class="form-control" type="text" :name=placeholder.name v-model=placeholder.value @blur=blurPlaceholderInput />
                                        </div>
                                    </div>
                                    <div class="form-group mb-2">
                                        <label for="adHeadline">Headline</label>
                                        <input type="text" class="form-control" id="adHeadline" v-model="headline">
                                        <p class="validation" v-if="headlineValidation !== ''">{{headlineValidation}}</p>
                                    </div>
                                    <div class="form-group mb-2">
                                        <label for="adDescription">Description</label>
                                        <input type="text" class="form-control" id="adDescription" v-model="description">
                                        <p class="validation" v-if="descriptionValidation !== ''">{{descriptionValidation}}</p>
                                    </div>
                                    <div class="form-group mb-4">
                                        <label for="adPrimaryText">Primary Text</label>
                                        <textarea class="form-control" id="adPrimaryText" style="height: 150px;" v-model="primaryText"></textarea>
                                        <p class="validation" v-if="primaryTextValidation !== ''">{{primaryTextValidation}}</p>
                                    </div>
                                    <div v-if="isSeller" class="form-group mb-2">
                                        <label for="linkUrl">Destination Url</label>
                                        <input type="text" class="form-control" id="linkUrl" v-model="linkUrl">
                                        <p class="validation" v-if="linkUrlValidation !== ''">{{linkUrlValidation}}</p>
                                    </div>
                                    <div v-if="isSeller" class="form-group mb-4">
                                        <label for="displayUrl">Display Url</label>
                                        <input type="text" placeholder="cincpro.com" class="form-control" id="displayUrl" v-model="displayUrl">
                                        <small class="text-muted">* Do not include "?src=FBSELL" in the url. This will be automatically added.</small>
                                        <p class="validation" v-if="displayUrlValidation !== ''">{{displayUrlValidation}}</p>
                                    </div>
                                    <div v-if="!isDynamicAd" class="ad-image-upload">
                                        <div class="form-group mb-2">
                                            <label for="adImage">Upload Image</label>
                                            <input type="file" accept="image/*" ref="imageFile" class="form-control-file" id="adImage" @change="handleImageUpload">
                                        </div>
                                        <div class="text-center">
                                            <strong>OR</strong>
                                        </div>
                                        <div class="form-group mb-2">
                                            <label for="ImageUrl">Image Link</label>
                                            <input type="text" class="form-control" id="ImageUrl" placeholder="http://" v-model="imageUrl">
                                            <span class="validation" v-if="imageValidation !== ''">{{imageValidation}}</span>
                                        </div>
                                    </div>
                                    <div class="form-row mt-3">
                                        <button type="button" button class="btn btn-primary ml-1" aria-disabled="true" :disabled=createDisabled @click="createAdRequest" :style="{ 'cursor': createDisabled ? 'wait' : 'pointer' }">
                                            <span v-if="createDisabled" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                            Create Ad
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                    <div v-if=displaySuccess>
                        <p class="display-1 text-success text-center"><i class="fa fa-check-circle-o"></i></p>
                        <h3 class="text-center text-success mb-4">Success!</h3>
                        <p class="text-center mb-4">The Ad has been created!</p>
                        <p class="text-center">
                            <button class="btn btn-primary" @click="displayAdForm">Create Another Ad</button>
                        </p>
                        <p class="text-center">
                            <router-link class="btn btn-secondary" to="/facebook/creator/adaccount">Create a new Ad Account</router-link>
                        </p>
                    </div>
                </div>
            </div>
            <div class="alert alert-info" role="alert" v-if=displayInfo>
                {{ infoMessage }}
            </div>
            <div class="alert alert-danger" role="alert" v-if=displayError>
                {{ errorMessage }}
            </div>
        </div>
    </div>

</template>

<script lang="ts">
import { Vue, Component } from 'vue-property-decorator';
import { CreatorInfo, CreatorInfoAd } from '../../models/CreatorInfo';
import { AdCreationRequest } from '../../models/request/AdCreationRequest';
import { ValidationResponse } from '../../models/ValidationResponse';
import { Ad } from '../../models/Ad';
import Sidebar from './Sidebar.vue';
import { AdTemplate } from '../../models/AdTemplate';

@Component({
    name: 'AdCreator',
    components: {
        Sidebar
    }

})
export default class AdCreator extends Vue{

    get selectedCampaign() {
        return this.$store.getters['creator/selectedCampaign'];          
    }

    get selectedAdSet() {
        this.getAdTexts(); //done here so that if the user selects a different adset while on the adset screen the proper templates load
        return this.$store.getters['creator/selectedAdSet'];
    }

    get selectedFormId(): string {
        return this.$store.state.creator.selectedFormId;
    }

    get isSeller() {
        return this.selectedCampaign.CampaignType === "Seller";
    }

    get isListCastAd() {
        return this.selectedCampaign.CampaignType === "ListCast";
    }

    get isTeamListingAd() {
        return this.selectedCampaign.CampaignType === "TeamListingAds";
    }

    get isDynamicAd() {
        return this.selectedCampaign.CampaignType === "ListCast" || this.selectedCampaign.CampaignType === "TeamListingAds";
    }

    adId: string = "";
    adsetId: string = "";

   readonly IMAGE_HEADER : string = ";base64,";

    createDisabled : boolean = false;
    displayInfo: boolean = false;
    displayError: boolean = false;
    displaySuccess: boolean = false;
    infoMessage: string = '';
    errorMessage: string = '';
    form : any;

    adName: string = "";
    description: string = "";
    headline: string = "";
    imageUrl: string = "";
    primaryText: string = "";
    fbImageHash : string = "";
    linkUrl: string = "";
    displayUrl: string = "";

    //dropdown values
    adTemplates : AdTemplate[] = new Array();

    selectedTemplateId : string = "";
    placeholders : PlaceHolderValue[] = [];
    selectedTemplate : AdTemplate = new AdTemplate();

    async createAdRequest(e: Event) {
        this.displayError = false;
        if(this.form){
            e.preventDefault();
            e.stopPropagation();
            if (this.form.checkValidity() === true) { 
                let adCreationRequest = new AdCreationRequest();
                adCreationRequest.AdAccountId = this.creatorInfo.AdAccountId;
                adCreationRequest.CampaignId = this.selectedCampaign.CampaignId;
                adCreationRequest.AdsetId = this.selectedAdSet.AdsetId;
                adCreationRequest.PageId = this.selectedAdSet.PageId;
                adCreationRequest.ProductSetId = this.selectedAdSet.ProductSetId;
                adCreationRequest.LinkDescription = this.description;
                adCreationRequest.LinkHeadline = this.headline;
                adCreationRequest.ImageUrl = this.imageUrl;
                adCreationRequest.FBImageHash = this.fbImageHash;
                adCreationRequest.FormId = this.selectedFormId;
                adCreationRequest.PrimaryText = this.primaryText;
                adCreationRequest.Name = this.adName;
                adCreationRequest.Company = this.creatorInfo.Company;
                adCreationRequest.CampaignType = this.selectedCampaign.CampaignType;
                adCreationRequest.LinkUrl = this.linkUrl;
                adCreationRequest.DisplayUrl = this.displayUrl;

                if(this.isDynamicAd)
                    adCreationRequest.DynamicAdType = this.selectedTemplate.displayType.toString();
                else
                    adCreationRequest.DynamicAdType = "0";

                this.displayInfo = true;
                this.infoMessage = 'Creating Ad...';
                this.createDisabled = true;
                this.$store.dispatch('creator/createCincConfiguredAd', adCreationRequest)
                .then((response: any) => {
                    this.setCreatorInfo(response.data.id);
                    this.displaySuccess = true;
                })
                .catch((error: any) => {
                    this.displayError = true;
                    this.displayInfo = false;
                    this.createDisabled = false;
                    this.errorMessage = `An error occurred while creating the ad. Please try again or report the issue. ${error}`;
                }).finally(() => {
                    this.createDisabled = false;
                    this.infoMessage = '';
                    this.displayInfo = false;
                });
            }
            this.form.classList.add('was-validated');
        }
        else{
            this.form = document.getElementsByClassName('needs-validation')[0];
            this.createAdRequest(e);
        }
    }

    //Validation
    validation : ValidationResponse = new ValidationResponse();
    get nameValidation() {
        return this.validation.getValidation(Ad.NameVal)
    }
    get headlineValidation() {
        return this.validation.getValidation(Ad.HeadlineVal);
    }
    get descriptionValidation() {
        return this.validation.getValidation(Ad.DescriptionVal);
    }
    get primaryTextValidation() {
        return this.validation.getValidation(Ad.BodyVal);
    }
    get imageValidation() {
        return this.validation.getValidation(Ad.ImageVal);
    }
    get ctaValidation() {
        return this.validation.getValidation(Ad.CallToActionVal);
    }
    get linkUrlValidation() {
        return this.validation.getValidation(Ad.LinkUrlVal);
    }
    get displayUrlValidation() {
        return this.validation.getValidation(Ad.DisplayUrlVal);
    }
    get creatorInfo() : CreatorInfo {
        return this.$store.state.creator.creatorInfo
    }  

    async created() {
        if(this.isSeller) {
            this.displayUrl = `${this.creatorInfo.DomainName}.com`;
        }
    }

    private clearSelectedAdTemplate() {
        this.description = "";
        this.headline = "";
        this.primaryText = "";
        this.adName = "";
    }

    async getAdTexts() {
        this.clearSelectedAdTemplate();

        let adTemplates = new Array<AdTemplate>();
        await this.$store.dispatch('ad/getAllTemplates')
            .then((response : any) => {
                adTemplates = response;
            }).catch((error : any) => {
                this.$toasted.global.error(`An error occurred retrieving the Ad Templates.<br/> ${error}`);
            });

        if(this.isListCastAd) {
            this.adTemplates = adTemplates.filter((template : AdTemplate) => template.templateType === 2);
        }
        else if(this.isTeamListingAd) {
            this.adTemplates = adTemplates.filter((template : AdTemplate) => template.templateType === 1);
        }
        else {
            this.adTemplates = adTemplates.filter((template : AdTemplate) => template.templateType === 0);
        }
    }

    displayAdForm() {
        this.displaySuccess = false;
        this.imageUrl = "";
        this.fbImageHash = "";
    }

    //User Events
    updateAdTextFields() : void {
        this.placeholders = [];
        this.templateTextChange();
    }

    handleImageUpload(event : any) {
        this.createDisabled = true;
        let files: FileList = event.target.files;
        let reader = new FileReader();
        let byteString! : string;
        reader.addEventListener("load", () => {
            if(reader.result) {
                byteString = <string>reader.result;
                let headerIndex = byteString.indexOf(this.IMAGE_HEADER);
                if(headerIndex !== -1) {
                    this.imageUrl = "";
                    byteString = byteString.substring(headerIndex + this.IMAGE_HEADER.length);
                    this.$store.dispatch('ad/createImageHash', {byteString: byteString, adAccountId: this.creatorInfo.AdAccountId, company: this.creatorInfo.Company })
                    .then((response : any) => {
                        this.fbImageHash = response.id;
                    }).catch((error: any) => {
                        this.$toasted.global.error(`An error occurred trying to upload the image.<br/> ${error}`);
                    }).finally(() => {
                        this.createDisabled = false;
                    });
                }
            }
        }, false);
        reader.readAsDataURL(files[0]);
    }

    //Ad Text Functionality 
    scrapePlaceholders(value : string) : void {
        let regEx = new RegExp("{{{([^}]+)}}}", 'g');
        let placeholder : any;

        while((placeholder = regEx.exec(value)) !== null) {
            let placeholderName = placeholder[0];
            if((this.placeholders.filter(ph => ph.name === placeholderName)).length === 0){
                let placeholderValue = new PlaceHolderValue();
                placeholderValue.name = placeholderName;
                this.placeholders.push(placeholderValue);
            }
        }
    }

    blurPlaceholderInput(event : FocusEvent) : void {
        let srcElement = <HTMLInputElement>(event.srcElement || event.target);
        if(srcElement && srcElement.value) {
            let placeHolderIndex = this.placeholders.findIndex(ph => ph.name == srcElement.name);
            if(placeHolderIndex > -1) {
                this.placeholders[placeHolderIndex].value = srcElement.value;
            }
        }
        this.replaceAllContent();
    }

    replaceAllContent() {
        this.headline = this.replaceContent(this.selectedTemplate.headline);
        this.primaryText = this.replaceContent(this.selectedTemplate.primaryText);
        this.description = this.replaceContent(this.selectedTemplate.description);
    }

    replaceContent(content: string) : string {
        let returnValue = content;
        if(content.trim().length > 0) {
            let _this = this;
            this.placeholders.forEach(function(placeHolder) {
                if(placeHolder.value) {
                    while(returnValue.indexOf(placeHolder.name) > -1) {
                        let placeHolderValue = _this.checkCapitalization(placeHolder.value, placeHolder.name, returnValue);
                        returnValue = returnValue.replace(placeHolder.name, placeHolderValue);
                    }
                }  
            });
        }
        return returnValue;
    }

    templateTextChange() : void {
        this.placeholders = [];
        
        this.selectedTemplate = this.adTemplates.find(template => template.id === this.selectedTemplateId) || new AdTemplate();

        this.adName = this.selectedTemplate.adName;

        this.headline = this.selectedTemplate.headline;
        this.scrapePlaceholders(this.selectedTemplate.headline);

        this.primaryText = this.selectedTemplate.primaryText;
        this.scrapePlaceholders(this.selectedTemplate.primaryText);

        this.description = this.selectedTemplate.description;
        this.scrapePlaceholders(this.selectedTemplate.description);
    }

    checkCapitalization(value: string, name: string, content: string) : string {
        let words = content.split(" ");
        let valueIndex = words.findIndex(word => word.indexOf(name) > -1);
        let preIndex = valueIndex - 1;
        let postIndex = valueIndex + 1;

        if(preIndex >= 0) {
            let checkValue = words[preIndex];
            if(checkValue == checkValue.toLocaleUpperCase()) {
                return value.toLocaleUpperCase();
            }
        }

        if(postIndex < words.length) {
            let checkValue = words[postIndex];
            if(checkValue == checkValue.toLocaleUpperCase()) {
                return value.toLocaleUpperCase();
            }
        }
        return value;
    }

    async setCreatorInfo(adId: string) {
        
        let ad: CreatorInfoAd = {
            AdId: adId,
            AdName: this.adName
        }

        if (this.selectedCampaign) {
            if (this.selectedAdSet) {
                (this.selectedAdSet).Ads.push(ad);
            }
        }
        await this.$store.dispatch('creator/setCreatorInfo', this.creatorInfo);
    }
}

class PlaceHolderValue { 
  name!: string;
  value!: string;
}

</script>