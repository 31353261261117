import { CincPlatform } from "../../models/CincPlatform";

const cincPlatformStore = ({
    namespaced: true,
    state : {},
    actions: {
        async getCincPlatforms({commit, state, rootState, rootGetters} : {commit:any, state:any, rootState: any, rootGetters :any}, search : string) {
            return await rootGetters.facebookMarketingFactory.GetCincPlatforms(search);
        },
        async getCincPlatform({commit, state, rootState, rootGetters} : {commit:any, state:any, rootState: any, rootGetters :any}, id : string) {
            return await rootGetters.facebookMarketingFactory.GetCincPlatform(id);
        }
    },
    mutations: {
        setCincPlatforms(state: any, cincPlatforms: CincPlatform[]){
            state.cincPlatforms = cincPlatforms;
        }
    }
});
export default cincPlatformStore;