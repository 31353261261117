var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", { attrs: { id: "productset-selector" } }, [
    _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "col-md-8" }, [
        _c("div", { staticClass: "form-group mb-3" }, [
          _c(
            "label",
            { staticClass: "form-label", attrs: { for: "productCatalogId" } },
            [_vm._v("Product Catalog Id")]
          ),
          _vm._v(" "),
          _c("div", { staticClass: "input-group mb-1" }, [
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.productCatalogId,
                  expression: "productCatalogId",
                },
              ],
              staticClass: "form-control",
              attrs: { type: "text", id: "productCatalogId" },
              domProps: { value: _vm.productCatalogId },
              on: {
                input: function ($event) {
                  if ($event.target.composing) return
                  _vm.productCatalogId = $event.target.value
                },
              },
            }),
            _vm._v(" "),
            _c(
              "button",
              {
                staticClass: "btn btn-primary ml-1",
                attrs: { type: "button", disabled: _vm.isLoading },
                on: {
                  click: function ($event) {
                    return _vm.productSetRequest(_vm.productCatalogId)
                  },
                },
              },
              [_vm._v("Get Product Sets")]
            ),
          ]),
          _vm._v(" "),
          _c("span", [
            _c("small", [
              _vm._v(
                "Catalog Name: " +
                  _vm._s(_vm.isLoaded ? _vm.productCatalogName : "")
              ),
            ]),
          ]),
        ]),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "col-md-4" }, [
        _c(
          "label",
          { staticClass: "form-label", attrs: { for: "productsets" } },
          [_vm._v("Product Sets")]
        ),
        _vm._v(" "),
        _c(
          "select",
          {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.productSetId,
                expression: "productSetId",
              },
            ],
            staticClass: "form-select",
            attrs: { id: "productsets", disabled: !_vm.isLoaded },
            on: {
              change: [
                function ($event) {
                  var $$selectedVal = Array.prototype.filter
                    .call($event.target.options, function (o) {
                      return o.selected
                    })
                    .map(function (o) {
                      var val = "_value" in o ? o._value : o.value
                      return val
                    })
                  _vm.productSetId = $event.target.multiple
                    ? $$selectedVal
                    : $$selectedVal[0]
                },
                _vm.updateProductSetId,
              ],
            },
          },
          [
            _c("option", { attrs: { disabled: "", value: "" } }, [
              _vm._v("Select Product Set"),
            ]),
            _vm._v(" "),
            _vm._l(_vm.productsetResults, function (productset) {
              return _c(
                "option",
                { key: productset.id, domProps: { value: productset.id } },
                [_vm._v(_vm._s(productset.name))]
              )
            }),
          ],
          2
        ),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }