export class ValidationResponse {
    public IsValid : boolean = true;
    public ErrorMessages : PropertyError[] = new Array();

    public getValidation(propName : string) {
        let validation = <PropertyError>this.ErrorMessages.find((message) => message.name === propName);
        return validation ? validation.error : "";
    }
}

export class PropertyError {
    name : string = "";
    error : string = "";
    constructor(name : string, error : string) {
        this.name = name;
        this.error = error;
    }
}
