export class CincPlatform {
    Id!: string;
    DomainName!: string;
    Status!: string;
    CompanyName!: string;
    ContactFirstName!: string;
    ContactLastName!: string;
    ContactAddress!: string;
    ContactCity!: string;
    ContactState!: string;
    ContactZip!: string;
    ContactPhone!:string;
    Country!: string;
}
