import Toasted from 'vue-toasted';

const ToastedPlugin = {
    install (Vue : any, toastOptions : any) {
        toastOptions = {
            position : 'top-right',
            duration : 6000
          };

        Vue.use(Toasted, toastOptions);

        Vue.toasted.register('error',
            (payload : any) => {
                return payload;
            },
            {
                type : 'error',
                duration : 0,
                action : {
                    text : 'x',
                    onClick : (e : any, toastObject : any) => {
                        toastObject.goAway(0);
                    }
                }
            }
        );
    }
};
  
export default ToastedPlugin;