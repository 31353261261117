<template>
  <aside class="sidebar d-flex flex-column flex-shrink-0 p-1 bg-light">
    <ul class="nav nav-pills flex-column mb-auto">
      <li class="nav-item">
        <router-link 
          v-if="creatorInfo.AdAccountName && creatorInfo.AdAccountName.trim() !== ''"
          class="nav-link" 
          to='#'
          @click.native.prevent="navigateToCampaign(creatorInfo.AdAccountName)"
          :class="{ 'active-link': $store.state.creator.activeItem === creatorInfo.AdAccountName }"
          exact
        >
          <font-awesome-icon :icon="['far', 'address-card']" /> 
          {{ creatorInfo.AdAccountName}}
        </router-link>
        <ul class="nav nav-pills flex-column indent-list" v-for="(campaign, index) in creatorInfo.Campaigns"
            :key="'campaign-' + index"
        >
          <router-link 
            class="nav-link"
            to="#"
            @click.native.prevent="navigateToAdset(campaign.CampaignId)" 
            :class="{ 'active-link': $store.state.creator.activeItem === campaign.CampaignId }" 
            exact
          >
            <font-awesome-icon :icon="['far', 'folder']" />
            {{ campaign.CampaignName }}
          </router-link>
          <ul class="nav nav-pills flex-column indent-list">
            <li class="nav-item" v-for="(adset, adsetIndex) in creatorInfoAdsets(campaign)" :key="'adset-' + adsetIndex">
              <router-link 
                class="nav-link"
                to="#"
                @click.native.prevent="navigateToForm(campaign.CampaignId, adset.AdsetId)" 
                :class="{ 'active-link': $store.state.creator.activeItem  === adset.AdsetId }" 
                exact
              >
                <font-awesome-icon icon=border-all />
                {{ adset.AdsetName}}
              </router-link>
              <ul class="nav nav-pills flex-column indent-list" v-if="adset.Ads && adset.Ads.length > 0">
                <li class="nav-item" v-for="(ad, adIndex) in creatorInfoAds(adset)" :key="'ad-' + adIndex">
                  <font-awesome-icon icon="ad" />
                  {{ ad.AdName }}
                </li>
              </ul>
            </li>
          </ul>
        </ul>
      </li>
    </ul>
  </aside>
</template>

<script lang="ts">

import { Component, Vue, Watch } from 'vue-property-decorator';
import { Route } from 'vue-router';

@Component({
  name: 'Sidebar',
})
export default class Sidebar extends Vue {
  activeItem = null;

  get creatorInfo() {
    return this.$store.state.creator.creatorInfo;
  }

  get creatorInfoCampaings() {
    return this.$store.state.creator.creatorInfo.Campaigns;
  }

  creatorInfoAdsets(campaign: any) {
    return campaign.Adsets;
  }

  creatorInfoAds(adset: any) {
    return adset.Ads;
  }

  navigateToCampaign(adAccountName: string) {
    this.$router.push('/facebook/creator/campaign').then(() => {
      this.$store.dispatch('creator/setActiveItem', adAccountName);
    });
  }

  navigateToAdset(campaignId: string) {
    this.$store.dispatch('creator/setSelectedCampaignId', campaignId);
    this.$router.push('/facebook/creator/adset').then(() => {
      this.$store.dispatch('creator/setActiveItem', campaignId);
    });
  }

  navigateToForm(campaignId: string, adsetId: string) {
    this.$store.dispatch('creator/setSelectedCampaignId', campaignId);
    this.$store.dispatch('creator/setSelectedAdsetId', adsetId);
    var selectedCampaign = this.$store.getters['creator/selectedCampaign'];
    if (selectedCampaign.CampaignType === 'Seller') {
      this.$router.push('/facebook/creator/ad').then(() => {
        this.$store.dispatch('creator/setActiveItem', adsetId);
      });
    }
    else {
      this.$router.push('/facebook/creator/form').then(() => {
        this.$store.dispatch('creator/setActiveItem', adsetId);
      });
    }
  }

  @Watch('$route', { immediate: true })
  onRouteChange(to: Route) {
    if (to.path === '/facebook/creator/adaccount') {
      this.$store.dispatch('creator/clearCreatorInfo');
    }
  }
}


</script>
