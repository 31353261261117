var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", { staticClass: "component-container" }, [
    _c("section", { staticClass: "vh-100" }, [
      _c("div", { staticClass: "container py-5 h-100" }, [
        _c(
          "div",
          {
            staticClass:
              "row d-flex justify-content-center align-items-center h-100",
          },
          [
            _c("div", { staticClass: "col-12 col-md-8 col-lg-6 col-xl-5" }, [
              _c(
                "div",
                {
                  staticClass: "card bg-dark text-white",
                  staticStyle: { "border-radius": "1rem" },
                },
                [
                  _c("div", { staticClass: "card-body p-5 text-center" }, [
                    _c("div", { staticClass: "mb-md-5 mt-md-4 pb-5" }, [
                      _c(
                        "h2",
                        { staticClass: "mb-5 text-uppercase display-6" },
                        [_vm._v("Facebook Automation Login")]
                      ),
                      _vm._v(" "),
                      _c("p", { staticClass: "text-white-50 mb-5" }, [
                        _vm._v("Please click the button below to login!"),
                      ]),
                      _vm._v(" "),
                      _c(
                        "a",
                        {
                          staticClass: "btn btn-outline-light btn-lg px-5",
                          attrs: { href: _vm.loginUrl },
                        },
                        [
                          _c("font-awesome-icon", {
                            attrs: { icon: "wand-magic-sparkles" },
                          }),
                          _c("span", { staticClass: "m-2" }, [
                            _vm._v("Magic Login"),
                          ]),
                        ],
                        1
                      ),
                    ]),
                  ]),
                ]
              ),
            ]),
          ]
        ),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }