<template>
  <div class="component-container">
    <section class="vh-100">
      <div class="container py-5 h-100">
        <div class="row d-flex justify-content-center align-items-center h-100">
          <div class="col-12 col-md-8 col-lg-6 col-xl-5">
            <div class="card bg-dark text-white" style="border-radius: 1rem;">
              <div class="card-body p-5 text-center">
                <div class="mb-md-5 mt-md-4 pb-5">
                  <h2 class="mb-5 text-uppercase display-6">Facebook Automation Login</h2>
                  <p class="text-white-50 mb-5">Please click the button below to login!</p>
                  <a class="btn btn-outline-light btn-lg px-5" :href="loginUrl">
                    <font-awesome-icon icon="wand-magic-sparkles"></font-awesome-icon><span class="m-2">Magic Login</span>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script lang="ts">
import { Vue, Component, Prop } from 'vue-property-decorator';
import { Config } from "../models/Config";

@Component
export default class Login extends Vue{
  private config : Config = require("../../config.json");
  loginUrl :string = `${this.config.apiUrl}api/User/login?redirectUrl=${this.config.redirectUrl}`;

  @Prop() ErrorDisplayMessage!: string;

  async mounted() {
    if(this.ErrorDisplayMessage){
      this.$toasted.global.error(this.ErrorDisplayMessage);
    }
  }
}
</script>

