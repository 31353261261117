<template>
    <div class="component-container">
        <div class="row">
            <div class="col-sm-6">
                <h1 class="display-6 mt-4 mb-3">Existing Reports</h1>
            </div>
            <div class="col-sm-6 text-end mt-4">
                <router-link class="btn btn-primary" to="/facebook/reports/create"><font-awesome-icon :icon="['fas', 'file']"/><span class="m-2">Request a new Report</span></router-link>
            </div>
        </div>
        <div class="row mb-1">
            <div class="col-sm-10">
                <span class="mb-">Current Folder: {{ currentFolderName }}</span>
            </div>
            <div class="col-sm-2 text-end" >
                <button type="button" :class="(currentFolderName != '/' ? 'btn btn-outline-secondary' : 'btn btn-outline-secondary disabled' )" @click="goUpFolderLevel()"><font-awesome-icon :icon="['fas', 'folder']" /><font-awesome-icon class="ms-1" :icon="['fas', 'up-long']" /></button>
            </div>
        </div>
        <div v-if="isLoading" class="text-center mt-3">
            <h1 class="display-6">Loading...</h1>
            <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
            <span class="visually-hidden">Loading...</span>
        </div>
        <div v-else class="list-group">
            <a href="#" class="list-group-item list-group-item-action" v-for="folder in contents.folders" :key="folder.name" @click="clickFolder(folder.name)">
                <font-awesome-icon :icon="['far', 'folder']" /><span class="ms-2">{{ folder.name }}</span>
            </a>
            <a href="#" class="list-group-item list-group-item-action" v-for="file in contents.fileInfos" :key="file.name" @click="clickFile(file.path, file.name)">
                <font-awesome-icon :icon="['far', 'file']" /><span class="ms-3">{{ file.name }}</span>
            </a>
        </div>
    </div>
</template>

<script lang="ts">
import { Vue, Component } from 'vue-property-decorator';
import { FolderContent } from '../../models/FolderContent';

@Component({
    name: 'ExistingReports'
})
export default class ExistingReports extends Vue {

    contents: FolderContent = new FolderContent();
    currentFolderName: string = "/";
    isLoading: boolean = true;

    created() {
        this.getFolderContent("/");
    }

    async goUpFolderLevel() {
        let path = this.currentFolderName.split('/');
        path.pop();
        path.pop();
        let prefix = path.join('/');
        await this.getFolderContent(`${prefix}/`);
    }

    async clickFolder(prefix:string) {
        await this.getFolderContent(prefix);
    }

    async clickFile(path:string, filename: string) {
        await this.$store.dispatch('report/downloadFile', path)
        .then((response) => {
            let element = document.createElement('a');
            element.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(response));
            element.setAttribute('download', filename);

            element.style.display = 'none';
            document.body.appendChild(element);

            element.click();
            document.body.removeChild(element); 
        });
    }

    async getFolderContent(prefix: string) {
        this.currentFolderName = prefix;
        await this.$store.dispatch('report/getFolderContent', prefix)
        .then((response) => {
            this.contents = response;
        }).finally(() => {
            this.isLoading = false;
        });
    }
}
</script>