<template>
    <div class="">
        <form class="needs-validation" novalidate>
            <div class="display-5 mb-3 mt-3">Import Ad Account from Facebook</div>
            <div class="row" v-if="!needsRequiredProperties">
                <div class="col-sm-10">
                    <p class="m-1 mb-3">Provide the company and an ad account id to import the account into the automation system. This can also be used to sync an account that is already part of the automation system.</p>
                    <select v-model="company" class="form-select" id="company" required>
                        <option selected disabled value=0>Please Select a Company</option>
                        <option value=1>CINC</option>
                        <option value=2>Real Geeks</option>
                    </select>
                    <div v-if="company != 0" class="input-group mt-3">
                        <input v-model="adAccountId" type="text" class="form-control" placeholder="Ad Account ID" required>
                        <button class="btn btn-outline-primary" type="button" :disabled="requestSubmitted" @click=loadAdAccount> 
                            <span v-if="requestSubmitted" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                            <span v-if="requestSubmitted" class="visually-hidden">Loading...</span>
                            <span>Submit</span>
                        </button>
                    </div>
                </div>
            </div>
            <div class="row" v-else>
                <div class="col-sm-10">
                    <div class="alert alert-warning">
                        <span>The supplied ad account was successfully found and can be imported but we need more detail.</span>
                    </div>
                    <span>* Required Properties * </span>
                    <div class="mt-3" v-for="(property, index) in requiredProperties" :key="index">
                        The {{property.ObjectType}} "{{property.ObjectName}}"" requires a {{property.PropertyName}}
                        <input v-model=property.Value type="text" class="form-control" :placeholder=property.PropertyName required />
                    </div>
                    <button class="btn btn-outline-primary mt-3" type="button" @click="loadAdAccount">Submit</button>
                </div>
            </div>
        </form>
    </div>
</template>

<script lang="ts">
import { Vue, Component } from 'vue-property-decorator';
import { CreatorInfoCreationRequest } from '../../../models/FormCreationRequest';
import { CreatorInfoMissingProperty } from '../../../models/CreatorInfoMissingProperty';

@Component({
    name: 'AdAccountLoader'
})
export default class AdAccountLoader extends Vue{

    adAccountId: string = '';
   company: number = 0;
   form: any;
   requestSubmitted = false;
   get needsRequiredProperties() {
       return this.requiredProperties.length > 0;
   }
   requiredProperties: CreatorInfoMissingProperty[] = [];

   mounted() {
        this.form = document.getElementsByClassName('needs-validation')[0];
    }

   loadAdAccount(e: Event) {
    if(this.form)
    {
        e.preventDefault();
        e.stopPropagation();

        if (this.form.checkValidity() === true) { 
            let request = new CreatorInfoCreationRequest();
            request.AdAccountId = this.adAccountId;
            request.Company = this.company;
            request.MissingProperties = this.requiredProperties;

            this.$store.dispatch('creator/createAdAccountCreatorInfo', request)
                .then(async (response) => {
                    if(response.status === 201) {//created
                        this.$router.push(`/facebook/creator/campaign`);
                    }
                    else if(response.status === 206) //partial content
                    {
                        this.requiredProperties = response.data.requiredProperties.map((property: any) => {
                            let missingProperty = new CreatorInfoMissingProperty();
                            missingProperty.PropertyName = property.propertyName;
                            missingProperty.ObjectId = property.objectId;
                            missingProperty.ObjectType = property.objectType;
                            missingProperty.ObjectName = property.objectName;
                            missingProperty.Value = "";
                            return missingProperty;
                        });
                    }
                })
                .catch((error: any) => {
                    this.$toasted.global.error(`An error occured while trying to load the ad account.`);
                });
            }
        }
        this.form.classList.add('was-validated');
   }
};
</script>
