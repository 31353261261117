export class AdCreationRequest{
    public AdAccountId!: string;
    public CampaignId!: string;
    public Name!: string;
    public AdsetId!: string;
    public PageId!: string;
    public FormId!: string;
    public ProductSetId!: string;
    public PrimaryText!: string;
    public LinkHeadline!: string;
    public LinkDescription!: string;
    public LinkUrl!: string;
    public DisplayUrl!: string;
    public CallToAction!: string;
    public ImageUrl!: string;
    public FBImageHash!: string;
    public Company!: string;
    public CampaignType!: string;
    public DynamicAdType!: string;
}