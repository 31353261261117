import { Company } from "../enums/Company";

export class CreatorInfo{
    public ClientFullName!: string;
    public DomainName!: string;
    public States!: string[];
    public AdAccountId!: string;
    public AdAccountName!: string;
    public Company!: Company;
    public Campaigns!: CreatorInfoCampaign[];
}

export class CreatorInfoCampaign{
    public CampaignType!: string;
    public CampaignName!: string;
    public CampaignId: string = "";
    public Adsets!: CreatorInfoAdset[];
}

export class CreatorInfoAdset{
    public AdsetId: string = "";
    public AdsetName!: string;
    public PageId!: string;
    public ProductSetId!: string;
    public Ads!: CreatorInfoAd[];
}

export class CreatorInfoAd{
    public AdId: string = "";
    public AdName!: string;
}