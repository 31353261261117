import { AdAccountPermissionsRequest } from "../../models/request/AdAccountPermissionsRequest";

const adAccountStore = ({
    namespaced: true,
    actions: {
        async grantAdAccountPermissions({rootState} : {rootState: any}, request : AdAccountPermissionsRequest) {
            return new Promise ((resolve, reject) => {
                rootState.facebookMarketingFactory.GrantAdAccountPermissions(request)
                .then((response : any) => {
                    resolve(response);
                }, (error : any) => {
                    reject(error);
                })
            })
        }
    }
});
export default adAccountStore;
