<template>
    <div id="productset-selector">
        <div class="row">
            <div class="col-md-8">
                <div class="form-group mb-3">
                    <label class="form-label" for="productCatalogId">Product Catalog Id</label>
                    <div class="input-group mb-1">
                        <input type="text" class="form-control" v-model="productCatalogId" id="productCatalogId">
                        <button type="button" class="btn btn-primary ml-1" @click="productSetRequest(productCatalogId)" :disabled="isLoading">Get Product Sets</button>
                    </div>
                    <span><small>Catalog Name: {{isLoaded ? productCatalogName: ""}}</small></span>
                </div>
            </div>
            <div class="col-md-4">
                <label class="form-label" for="productsets">Product Sets</label>
                <select id="productsets" v-model="productSetId" class="form-select" @change="updateProductSetId" :disabled="!isLoaded">
                    <option disabled value="">Select Product Set</option>
                    <option v-for="productset in productsetResults" :key="productset.id" :value="productset.id">{{productset.name}}</option>
                </select>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator';
import { SearchProductSetResult } from '../../../models/ProductSet';
import { ProductCatalogSet } from '../../../models/ProductCatalogSet';

@Component
export default class ProductSetSelector extends Vue {
    productsetResults : SearchProductSetResult[] = new Array();
    productCatalogId : string = "";
    productCatalogName : string = "";
    productSetId : string = "";
    isLoading : boolean = false;
    isLoaded : boolean = false; 

    productSetRequest(productCatalogId : string) {
        this.isLoading = true;
        this.isLoaded = false;

        let company = this.$store.state.creator.creatorInfo.Company;
    
        let productCatalogSet: ProductCatalogSet =  {
            productCatalogId: productCatalogId,
            company: company
        };
        
        this.$store.dispatch('productset/getProductSetsbyProductCatalogId', productCatalogSet)
            .then((response : any) => {
                this.productsetResults = response[0].product_sets.data;
                this.productCatalogName = response[0].name;
                this.isLoading = false;
                this.isLoaded = true; 
            })
            .catch((error : any) => {
                this.$toasted.global.error(`Invalid product catalog id.<br/> ${error}`);
                this.isLoading = false;
                this.isLoaded = false;
            });
    }

    updateProductSetId() {
        this.$emit('product-set-changed', this.productSetId, this.productCatalogId);
    }

}
</script>

