var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", {}, [
    _c("form", { staticClass: "needs-validation", attrs: { novalidate: "" } }, [
      _c("div", { staticClass: "display-5 mb-3 mt-3" }, [
        _vm._v("Ad Account Permissions Granter"),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "col-sm-10" }, [
        _c("p", { staticClass: "m-1 mb-3" }, [
          _vm._v(
            "This tool is used to grant our Facebook Marketing team permissions to manage an account by Ad Account ID."
          ),
        ]),
        _vm._v(" "),
        _c(
          "select",
          {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.company,
                expression: "company",
              },
            ],
            staticClass: "form-select",
            attrs: { id: "company", required: "" },
            on: {
              change: function ($event) {
                var $$selectedVal = Array.prototype.filter
                  .call($event.target.options, function (o) {
                    return o.selected
                  })
                  .map(function (o) {
                    var val = "_value" in o ? o._value : o.value
                    return val
                  })
                _vm.company = $event.target.multiple
                  ? $$selectedVal
                  : $$selectedVal[0]
              },
            },
          },
          [
            _c(
              "option",
              { attrs: { selected: "", disabled: "", value: "0" } },
              [_vm._v("Please Select a Company")]
            ),
            _vm._v(" "),
            _c("option", { attrs: { value: "1" } }, [_vm._v("CINC")]),
            _vm._v(" "),
            _c("option", { attrs: { value: "2" } }, [_vm._v("Real Geeks")]),
          ]
        ),
        _vm._v(" "),
        _vm.company != 0
          ? _c("div", { staticClass: "input-group mt-3" }, [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.AdAccountId,
                    expression: "AdAccountId",
                  },
                ],
                staticClass: "form-control",
                class: _vm.isValid ? "" : "is-invalid",
                attrs: {
                  id: "adAccountId",
                  type: "text",
                  placeholder: "Ad Account Id",
                },
                domProps: { value: _vm.AdAccountId },
                on: {
                  input: function ($event) {
                    if ($event.target.composing) return
                    _vm.AdAccountId = $event.target.value
                  },
                },
              }),
              _vm._v(" "),
              _c(
                "button",
                {
                  staticClass: "btn btn-outline-primary",
                  attrs: { type: "button", disabled: _vm.RequestSubmitted },
                  on: { click: _vm.clickGrant },
                },
                [
                  _vm.RequestSubmitted
                    ? _c("span", {
                        staticClass: "spinner-border spinner-border-sm",
                        attrs: { role: "status", "aria-hidden": "true" },
                      })
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.RequestSubmitted
                    ? _c("span", { staticClass: "visually-hidden" }, [
                        _vm._v("Loading..."),
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _c("span", [_vm._v("Grant")]),
                ]
              ),
            ])
          : _vm._e(),
        _vm._v(" "),
        !_vm.isValid
          ? _c("div", { staticClass: "row text-danger mt-1" }, [
              _c("div", { staticClass: "col" }, [
                _c("div", {
                  staticClass: "alert alert-danger",
                  attrs: { role: "alert" },
                  domProps: { innerHTML: _vm._s(_vm.ValidationFeedback) },
                }),
              ]),
            ])
          : _vm._e(),
        _vm._v(" "),
        _vm.isSuccessful
          ? _c("div", { staticClass: "row text-success mt-1" }, [
              _c("div", { staticClass: "col" }, [
                _c("div", {
                  staticClass: "alert alert-success",
                  attrs: { role: "alert" },
                  domProps: { innerHTML: _vm._s(_vm.SuccessFeedback) },
                }),
              ]),
            ])
          : _vm._e(),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }