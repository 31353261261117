var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "d-flex" },
    [
      _c("Sidebar"),
      _vm._v(" "),
      _c("div", { staticClass: "component-container-sidebar" }, [
        _c("h6", { staticClass: "ms-3 mt-3" }, [
          _vm._v(
            "Account ID: " + _vm._s(_vm.creatorInfo.AdAccountId.substring(4))
          ),
        ]),
        _vm._v(" "),
        _c("h6", { staticClass: "ms-3" }, [
          _vm._v("Campaign ID: " + _vm._s(_vm.selectedCampaign.CampaignId)),
        ]),
        _vm._v(" "),
        _c("h6", { staticClass: "ms-3" }, [
          _vm._v("Ad Set ID: " + _vm._s(_vm.selectedAdSet.AdsetId)),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "card mb-3 mt-3" }, [
          _c("div", { staticClass: "card-header" }, [
            _vm._v("\n                Form Creator\n            "),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "card-body" }, [
            !_vm.displaySuccess
              ? _c(
                  "form",
                  {
                    staticClass: "needs-validation",
                    attrs: { novalidate: "" },
                  },
                  [
                    _vm.isBuyer()
                      ? _c("div", { staticClass: "form-row mb-3" }, [
                          _c("div", { staticClass: "row" }, [
                            _c("div", { staticClass: "col-md-6" }, [
                              _c("label", { attrs: { for: "location" } }, [
                                _vm._v("Location"),
                              ]),
                              _vm._v(" "),
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.location,
                                    expression: "location",
                                  },
                                ],
                                staticClass: "form-control",
                                attrs: {
                                  type: "text",
                                  id: "location",
                                  placeholder: "Location Name",
                                  required: "",
                                },
                                domProps: { value: _vm.location },
                                on: {
                                  input: function ($event) {
                                    if ($event.target.composing) return
                                    _vm.location = $event.target.value
                                  },
                                },
                              }),
                              _vm._v(" "),
                              _c("small", { staticClass: "text-muted" }, [
                                _vm._v("*used for form name only"),
                              ]),
                            ]),
                            _vm._v(" "),
                            _c("div", { staticClass: "col-md-6" }, [
                              _c("label", { attrs: { for: "niche" } }, [
                                _vm._v("Niche/Ad Type"),
                              ]),
                              _vm._v(" "),
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.niche,
                                    expression: "niche",
                                  },
                                ],
                                staticClass: "form-control",
                                attrs: {
                                  type: "text",
                                  id: "niche",
                                  placeholder: "Pools",
                                  required: "",
                                },
                                domProps: { value: _vm.niche },
                                on: {
                                  input: function ($event) {
                                    if ($event.target.composing) return
                                    _vm.niche = $event.target.value
                                  },
                                },
                              }),
                            ]),
                          ]),
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.isCinc() && _vm.isBuyer()
                      ? _c("div", { staticClass: "form-row mb-3" }, [
                          _c("div", { staticClass: "row" }, [
                            _c("div", { staticClass: "col-md-12" }, [
                              _c("label", { attrs: { for: "savedSearch" } }, [
                                _vm._v("Saved Search"),
                              ]),
                              _vm._v(" "),
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.savedSearch,
                                    expression: "savedSearch",
                                  },
                                ],
                                staticClass: "form-control",
                                attrs: {
                                  type: "text",
                                  id: "savedSearch",
                                  placeholder: "Saved Search",
                                  required: "",
                                },
                                domProps: { value: _vm.savedSearch },
                                on: {
                                  input: function ($event) {
                                    if ($event.target.composing) return
                                    _vm.savedSearch = $event.target.value
                                  },
                                },
                              }),
                              _vm._v(" "),
                              _c("small", { staticClass: "text-muted" }, [
                                _vm._v("*used for tracking parameter"),
                              ]),
                            ]),
                            _vm._v(" "),
                            _c("div", { staticClass: "col-md-6" }, [
                              _c("label", { attrs: { for: "favoriteCity" } }, [
                                _vm._v("Favorite City"),
                              ]),
                              _vm._v(" "),
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.favoriteCity,
                                    expression: "favoriteCity",
                                  },
                                ],
                                staticClass: "form-control",
                                attrs: {
                                  type: "text",
                                  id: "favoriteCity",
                                  placeholder: "Favorite City",
                                  required: "",
                                },
                                domProps: { value: _vm.favoriteCity },
                                on: {
                                  input: function ($event) {
                                    if ($event.target.composing) return
                                    _vm.favoriteCity = $event.target.value
                                  },
                                },
                              }),
                              _vm._v(" "),
                              _c("small", { staticClass: "text-muted" }, [
                                _vm._v("*used for tracking parameter"),
                              ]),
                            ]),
                            _vm._v(" "),
                            _c("div", { staticClass: "col-md-6" }, [
                              _c("label", { attrs: { for: "src" } }, [
                                _vm._v("Source"),
                              ]),
                              _vm._v(" "),
                              _c("div", { staticClass: "input-group" }, [
                                _c(
                                  "span",
                                  { staticClass: "input-group-text" },
                                  [_vm._v("FB_")]
                                ),
                                _vm._v(" "),
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.source,
                                      expression: "source",
                                    },
                                  ],
                                  staticClass: "form-control",
                                  attrs: {
                                    type: "text",
                                    placeholder: "Source",
                                    id: "src",
                                    required: "",
                                  },
                                  domProps: { value: _vm.source },
                                  on: {
                                    input: [
                                      function ($event) {
                                        if ($event.target.composing) return
                                        _vm.source = $event.target.value
                                      },
                                      _vm.validateInput,
                                    ],
                                  },
                                }),
                              ]),
                            ]),
                          ]),
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    _c("div", { staticClass: "form-row mb-3" }, [
                      _c("div", { staticClass: "col-md-12" }, [
                        _c("label", { attrs: { for: "link" } }, [
                          _vm._v("Destination Url"),
                        ]),
                        _vm._v(" "),
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.link,
                              expression: "link",
                            },
                          ],
                          staticClass: "form-control",
                          attrs: {
                            type: "text",
                            id: "link",
                            placeholder: "",
                            required: "",
                          },
                          domProps: { value: _vm.link },
                          on: {
                            input: function ($event) {
                              if ($event.target.composing) return
                              _vm.link = $event.target.value
                            },
                          },
                        }),
                        _vm._v(" "),
                        _vm.isCinc()
                          ? _c("small", { staticClass: "text-muted" }, [
                              _vm._v(
                                '* Do not include "?src=SOURCE&fbprompt=1" in the url.'
                              ),
                            ])
                          : _vm._e(),
                        _vm._v(" "),
                        !_vm.isCinc()
                          ? _c("small", { staticClass: "text-muted" }, [
                              _vm._v(
                                '* Do not include "?fb-lead&utm_source=Real+Geeks&utm_medium=Facebook&utm_campaign=Lead+Ads in the url."'
                              ),
                            ])
                          : _vm._e(),
                      ]),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "form-row mt-3" }, [
                      _c(
                        "button",
                        {
                          staticClass: "btn btn-primary ml-1",
                          style: {
                            cursor: _vm.createDisabled ? "wait" : "pointer",
                          },
                          attrs: {
                            type: "button",
                            "aria-disabled": "true",
                            disabled: _vm.createDisabled,
                          },
                          on: { click: _vm.createFormRequest },
                        },
                        [
                          _vm.createDisabled
                            ? _c("span", {
                                staticClass: "spinner-border spinner-border-sm",
                                attrs: {
                                  role: "status",
                                  "aria-hidden": "true",
                                },
                              })
                            : _vm._e(),
                          _vm._v(
                            "\n                            Create Form\n                        "
                          ),
                        ]
                      ),
                    ]),
                  ]
                )
              : _vm._e(),
            _vm._v(" "),
            _vm.displaySuccess
              ? _c("div", [
                  _vm._m(0),
                  _vm._v(" "),
                  _c("h3", { staticClass: "text-center text-success mb-4" }, [
                    _vm._v("Success!"),
                  ]),
                  _vm._v(" "),
                  _c("p", { staticClass: "text-center mb-4" }, [
                    _vm._v("The Form has been created!"),
                  ]),
                  _vm._v(" "),
                  _c(
                    "p",
                    { staticClass: "text-center" },
                    [
                      _c(
                        "router-link",
                        {
                          staticClass: "btn btn-primary",
                          attrs: { to: "/facebook/creator/ad" },
                        },
                        [_vm._v("Create a New Ad")]
                      ),
                    ],
                    1
                  ),
                ])
              : _vm._e(),
          ]),
        ]),
        _vm._v(" "),
        _vm.displayFormSelection() ? _c("h3", [_vm._v(" - Or - ")]) : _vm._e(),
        _vm._v(" "),
        _vm.displayFormSelection()
          ? _c("div", { staticClass: "card mb-3 mt-3" }, [
              _c("div", { staticClass: "card-header" }, [
                _vm._v("\n                Form Selection\n            "),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "card-body" }, [
                _c("div", { staticClass: "row g-3" }, [
                  _c("div", { staticClass: "col-12" }, [
                    _c(
                      "label",
                      { staticClass: "form-label", attrs: { for: "form" } },
                      [_vm._v("Select Form")]
                    ),
                    _vm._v(" "),
                    _c(
                      "select",
                      {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.selectFormId,
                            expression: "selectFormId",
                          },
                        ],
                        staticClass: "form-select",
                        attrs: { id: "adForm" },
                        on: {
                          change: function ($event) {
                            var $$selectedVal = Array.prototype.filter
                              .call($event.target.options, function (o) {
                                return o.selected
                              })
                              .map(function (o) {
                                var val = "_value" in o ? o._value : o.value
                                return val
                              })
                            _vm.selectFormId = $event.target.multiple
                              ? $$selectedVal
                              : $$selectedVal[0]
                          },
                        },
                      },
                      [
                        _c("option", { attrs: { value: "" } }, [
                          _vm._v("Please select a Lead Form"),
                        ]),
                        _vm._v(" "),
                        _vm._l(_vm.facebookForms, function (form) {
                          return _c(
                            "option",
                            { key: form.key, domProps: { value: form.id } },
                            [_vm._v(_vm._s(form.name))]
                          )
                        }),
                      ],
                      2
                    ),
                  ]),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "row mt-3" }, [
                  _c("div", { staticClass: "col-6" }, [
                    _c(
                      "button",
                      {
                        staticClass: "btn btn-primary ms-1",
                        attrs: { type: "button", "aria-disabled": "true" },
                        on: { click: _vm.selectExistingForm },
                      },
                      [_vm._v("Select Form")]
                    ),
                  ]),
                ]),
              ]),
            ])
          : _vm._e(),
        _vm._v(" "),
        _vm.displayInfo
          ? _c(
              "div",
              { staticClass: "alert alert-info", attrs: { role: "alert" } },
              [
                _vm._v(
                  "\n            " + _vm._s(_vm.infoMessage) + "\n        "
                ),
              ]
            )
          : _vm._e(),
        _vm._v(" "),
        _vm.displayError
          ? _c(
              "div",
              { staticClass: "alert alert-danger", attrs: { role: "alert" } },
              [
                _vm._v(
                  "\n            " + _vm._s(_vm.errorMessage) + "\n        "
                ),
              ]
            )
          : _vm._e(),
      ]),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("p", { staticClass: "display-1 text-success text-center" }, [
      _c("i", { staticClass: "fa fa-check-circle-o" }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }