<template>
    <div>
    </div>
</template>
  
<script lang="ts">
import { Vue, Component } from 'vue-property-decorator';
    
@Component
export default class TokenManager extends Vue {
    async mounted() {
        var accessToken = this.$route.query.access_token;
        await this.$store.dispatch('setAccessToken', accessToken);
        this.$router.push({ path: '/facebook/creator/adaccount' });
    }
}
</script>